/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptimizedReport } from '@SiteOwl/core';
import { Options } from 'ngx-slider-v2';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DBDevicePinSizeValue } from '../../constant/floor.constant';
import { FloorUtils } from '../../service/floor-utils';
import * as _ from "lodash";

@Component({
  selector: 'so-icon-plan-report',
  templateUrl: './icon-plan-report.component.html',
  styleUrls: ['./icon-plan-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPlanReportComponent implements AfterViewInit {
  pinSizeValue: any;
  pinSizeOptions: Options = {
    floor: 18,
    ceil: 60,
    step: 6,
    showTicks: true
  };
  floorId!: any;
  pinColor!: any;
  assignedToFilter!: any
  assignedTo: any = null;
  installByUserItems!: any;
  filterCategory!: any;
  projectId!: any;
  optimizedReportOptions = OptimizedReport
  optimizedFor: any = OptimizedReport[0].value;
  isFromSite!: any;
  user!: any
  public event: EventEmitter<any> = new EventEmitter();
  ngxSliderReady = false;

  constructor(public bsModalRef: BsModalRef, private cdRef: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    const timeOut = setTimeout(() => {
      this.ngxSliderReady = true;
      this.cdRef.detectChanges();
      clearTimeout(timeOut);
    }, 100);
  }
  closeModal() {
    this.bsModalRef.hide();
  }

  pinSizeChange(e: any) {
    this.pinSizeValue = e;
  }
  optimizedChanged(item: any) {
    this.optimizedFor = item.value
  }
  filterCategoryChanged(item: any) {
    item.isChecked = !item.isChecked;
  }
  filterLabel(filter: any) {
    return {
      'show-part-number': filter.id === 2,
      'show-device-status': filter.id === 8,
      'show-install-status': filter.id === 11
    }
  }
  downloadIcon() {
    const archived = this.filterCategory.filter((x: any) => x.type === 'vp' && x.category.key === 'showArchived');
    if (archived.length > 0) {
      archived[0].isChecked = !archived[0].isChecked;
    }
    const reportViewPrefrence: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'vp');
    const systemTypeFilter: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'st');
    const deviceStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'op');
    const installStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'is');
    const downloadData: any = {
      floorId: this.floorId,
      viewingPreference: reportViewPrefrence,
      systemTypeFilter: systemTypeFilter,
      deviceStatus: deviceStatus,
      installStatus: installStatus,
      equipmentSize: parseFloat(DBDevicePinSizeValue[this.pinSizeValue]),
      pinColor: this.pinColor,
      assignedTo: this.assignedTo !== null && this.assignedTo !== undefined ? parseInt(this.assignedTo) : null,
      optimizedFor: this.optimizedFor,
    }
    const filterDataDownload: any = FloorUtils.setDownloadData(downloadData);
    downloadData.viewingPreference = _.cloneDeep(filterDataDownload['viewingPreference']);
    downloadData.systemTypeFilter = _.cloneDeep(filterDataDownload['systemTypeFilterArr']);
    downloadData.deviceStatus = _.cloneDeep(filterDataDownload['deviceStatusFilterArr']);
    downloadData.installStatus = _.cloneDeep(filterDataDownload['installStatusArr']);
    this.event.emit({ downloadData: downloadData });
  }
}
