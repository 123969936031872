/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PointStore } from '../../utils/ps';
import * as _ from 'lodash';
import { Factory, Point } from '../../utils/point';
import { EventEmitterType, EventService, Utilities } from '@SiteOwl/core';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'so-cable-path',
  templateUrl: './cable-path.component.html',
  styleUrls: ['./cable-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CablePathComponent implements OnChanges, AfterViewInit {
  @Input() device: any;
  @Input() selectedDevice: any;
  @Input() isEdit = false;
  @Input() showConnectionLine: any
  @Input() filterCategoryData: any;
  @Input() isProject: any;
  @Input() assignedToFilter: any;
  @Input() isCableEnable: any;
  @Input() equipmentDetailsParent: any;
  @Input() unitOfMeasurements: any;
  @Input() hideLiveDevice: any;
  @Output() updateConnection = new EventEmitter();
  domDraggingNode: any;
  constructor(private cd: ChangeDetectorRef, private eventService: EventService) {
    PointStore.notify = (isEmit: any) => {
      if (this.isEdit && this.selectedDevice && !this.selectedDevice.isArchived && !this.selectedDevice.isLiveDevice && this.selectedDevice.cableCoordinates !== null && !this.domDraggingNode) {
        this.selectedDevice.cableCoordinates = _.cloneDeep(PointStore.getAllPoints());
        if (this.selectedDevice.cableCoordinates.length === 2) {
          this.selectedDevice.cableCoordinates = null;
        }
        this.updateCableCoordinates(isEmit).then((x: any) => {
          this.cd.detectChanges();
        });
      }
    }
  }
  childCableTrackByFn(index: any, child: any) {
    return child.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    Sentry.metrics.increment("ngOnChanges.cablepath.called", 1);
    if (changes['device']) {
      this.device = _.cloneDeep(changes['device'].currentValue);
      this.initialRender();
    } else if (changes['isEdit'] || changes['selectedDevice']) {
      this.initialRender();
      if (this.selectedDevice === null && changes['selectedDevice'] && changes['selectedDevice'].previousValue && changes['selectedDevice'].previousValue !== undefined && changes['selectedDevice'].previousValue !== null && (changes['selectedDevice'].currentValue && !changes['selectedDevice'].currentValue.isArchived && !changes['selectedDevice'].currentValue.isLiveDevice)) {
        Factory.createPath(0, 0, changes['selectedDevice'].previousValue.id, 0, changes['selectedDevice'].previousValue, false);
      }
    }
  }

  ngAfterViewInit() {
    if (!Utilities.isNull(this.selectedDevice) && !Utilities.isEmpty(this.selectedDevice) && (this.selectedDevice.cableCoordinates !== null || this.selectedDevice.networkSwitchLinkId > 0 || (this.selectedDevice.networkSwitchLinkEquipments && this.selectedDevice.networkSwitchLinkEquipments.length > 0))) {
      this.initialRender();
    }
  }


  initialRender() {
    if (!this.showConnectionLine && this.selectedDevice) {
      if ((this.device.networkSwitchLinkId && this.device.networkSwitchLinkId > 0) || this.device.childFloorEquipments.filter((e: any) => e.networkSwitchLinkId > 0).length > 0) {
        if ((this.device.networkSwitchLinkId === this.selectedDevice.id || this.device.networkSwitchLinkId === this.selectedDevice.parentId || this.selectedDevice.childFloorEquipments.filter((e: any) => e.id === this.device.networkSwitchLinkId).length > 0) || (this.equipmentDetailsParent && this.equipmentDetailsParent.childFloorEquipments.filter((e: any) => e.id === this.device.networkSwitchLinkId).length > 0) || this.device.childFloorEquipments.filter((e: any) => (e.networkSwitchLinkId === this.selectedDevice.id || (this.selectedDevice.childFloorEquipments && this.selectedDevice.childFloorEquipments.filter((x: any) => x.id === e.networkSwitchLinkId).length > 0))).length > 0) {
          if (this.device.networkSwitchLinkId === this.selectedDevice.id || this.device.networkSwitchLinkId === this.selectedDevice.parentId || this.selectedDevice.childFloorEquipments.filter((e: any) => e.id === this.device.networkSwitchLinkId).length > 0 || (this.equipmentDetailsParent && this.equipmentDetailsParent.childFloorEquipments.filter((e: any) => e.id === this.device.networkSwitchLinkId).length > 0)) {
            this.device['showConnectionLine'] = true;
          } else {
            this.device['showConnectionLine'] = false;
          }
          if (this.device.childFloorEquipments.filter((e: any) => (e.networkSwitchLinkId === this.selectedDevice.id || (this.selectedDevice.childFloorEquipments && this.selectedDevice.childFloorEquipments.filter((x: any) => x.id === e.networkSwitchLinkId).length > 0))).length > 0) {
            this.device.childFloorEquipments.filter((e: any) => e.networkSwitchLinkId === this.selectedDevice.id).forEach((element: any) => {
              element['showConnectionLine'] = true;
            });
          } else {
            this.device.childFloorEquipments.filter((e: any) => e.networkSwitchLinkId === this.selectedDevice.id).forEach((element: any) => {
              element['showConnectionLine'] = false;
            });
          }
        } else {
          this.device['showConnectionLine'] = false;
          if (this.device.childFloorEquipments && this.device.childFloorEquipments.length > 0) {
            this.device.childFloorEquipments.forEach((element: any) => {
              element['showConnectionLine'] = false;
            });
          }
        }
      }
    }
    if (this.selectedDevice) {
      const remPoint: any = document.querySelectorAll(`[id^="guideContainer-cable-"]`);
      if (remPoint && remPoint.length > 0) {
        for (const element of remPoint) {
          if (element.id !== "guideContainer-cable-" + this.selectedDevice.id) {
            element.innerHTML = '';
          }
        }
      }
    }
    if (this.isEdit && this.selectedDevice && this.selectedDevice !== null && !this.selectedDevice.isArchived && !this.selectedDevice.isLiveDevice && this.selectedDevice.cableCoordinates !== null && this.selectedDevice.cableCoordinates !== undefined) {

      PointStore.removeAllPoints().then(() => {
        if (typeof this.selectedDevice.cableCoordinates === 'string') {
          this.selectedDevice.cableCoordinates = JSON.parse(this.selectedDevice.cableCoordinates);
        }
        if (this.selectedDevice.cableCoordinates !== null) {
          this.selectedDevice.cableCoordinates.forEach((element: any, index: any) => {
            let id = 0
            if (index === 0) {
              id = this.selectedDevice.id
            } else if (index === this.selectedDevice.cableCoordinates.length - 1) {
              id = this.selectedDevice.networkSwitchLinkId
            } else {
              id = index + 1
            }
            if (!document.getElementById('circle-' + this.selectedDevice.id + '-' + (index + 1))) {
              this.createPoint(this.selectedDevice, (index + 1), element.x, element.y, id, true);
            }
          });
        }
      })
    } else {
      if (this.selectedDevice && !this.selectedDevice.isArchived && !this.selectedDevice.hideLiveDevice) {
        PointStore.removeAllPoints().then(() => {
          if (this.isEdit && this.selectedDevice && this.selectedDevice.id > 0) {
            if (!document.getElementById('circle-' + this.selectedDevice.id + '-1')) {
              this.createPoint(this.selectedDevice, 1, this.selectedDevice.imageLeft, this.selectedDevice.imageTop, this.selectedDevice.id, true);
            }
            if (!document.getElementById('circle-' + this.selectedDevice.id + '-2')) {
              this.createPoint(this.selectedDevice, 2, this.selectedDevice.cableLinkImageLeft, this.selectedDevice.cableLinkImageTop, this.selectedDevice.networkSwitchLinkId, true);
            }
          }
        })
      }
    }
    if (this.selectedDevice === null || this.selectedDevice === undefined) {
      Point.removeSelectionDevice();
    }
  }

  addNewPointToLine(event: any) {
    event.stopPropagation();
    if (this.isEdit && this.selectedDevice !== null && !this.selectedDevice.isLiveDevice && !this.selectedDevice.isArchived && this.isCableEnable) {
      const container: any = document.getElementById('FloorContainer');
      const width: any = _.cloneDeep(container.clientWidth)
      const height: any = _.cloneDeep(container.clientHeight)
      let points: any = _.cloneDeep(this.selectedDevice.cableCoordinates);
      const midx = (100 * event.offsetX / width).toFixed(5) + '%';
      const midy = (100 * event.offsetY / height).toFixed(5) + '%';
      if (PointStore.getAllPoints() != null && PointStore.getAllPoints().length < 22) {
        if (this.selectedDevice.cableCoordinates === null) {
          points = [];
          let left: any = this.selectedDevice.imageLeft;
          let top: any = this.selectedDevice.imageTop;
          if (this.selectedDevice.parentId > 0) {
            left = this.equipmentDetailsParent.imageLeft;
            top = this.equipmentDetailsParent.imageTop;
          }
          points.push({ x: left, y: top, index: 1, id: this.selectedDevice.id })
          points.push({ x: this.selectedDevice.cableLinkImageLeft, y: this.selectedDevice.cableLinkImageTop, index: 2, id: this.selectedDevice.networkSwitchLinkId })
          this.addPointAtMiddle(midx, midy, points, event);
        } else {
          this.addPointAtMiddle(midx, midy, points, event);
        }
      }
    }
  }

  addPointAtMiddle(midx: any, midy: any, points: any, event: any) {
    const newPoint: any = {
      x: midx,
      y: midy,
      index: points.length + 1,
      id: points.length + 1,
    }
    const addIndex: any = points.findIndex((z: any) => z.x === event.target.getAttribute("x1") && z.y === event.target.getAttribute("y1"));
    if (addIndex > -1) {
      points.splice((addIndex + 1), 0, newPoint);
    }
    this.renderPoints(points, newPoint);
  }

  createPoint(device: any, index: any, x: any, y: any, linkId: any, isViewPoint?: boolean, newPointAdded?: any) {
    if (this.isCableEnable) {
      const svg: any = document.getElementById("svg-cable-" + device.id);
      const guideContainer: any = document.getElementById("guideContainer-cable-" + device.id);
      if (svg && guideContainer) {
        const newPoint: any = Factory.createPath(x, y, device.id, index, this.selectedDevice, true);
        if (newPoint && newPoint !== undefined && newPoint !== null) {
          PointStore.addNewPoint(newPoint, index, linkId);
          const domNode = newPoint.getNode();
          if (domNode) {
            domNode.classList.add("base-node");
            if (index === 1 || (device.cableCoordinates !== null && index === (isViewPoint ? device.cableCoordinates.length : device.cableCoordinates.length + 1))) {
              domNode.style.opacity = "0";
            } else if (index === 2 && device.cableCoordinates === null) {
              if (!newPointAdded) {
                domNode.style.opacity = "0";
              }
            }
          }
          guideContainer.appendChild(domNode);
          if (newPointAdded && newPointAdded.index === index) {
            this.domDraggingNode = domNode;

          }
        }
      }
    }
  }

  renderPoints(points: any, newPoint: any) {
    PointStore.removeAllPoints().then(() => {
      points.forEach((element: any, index: any) => {
        element.index = index + 1
        let isViewPoint = false;
        if (index > 0 && index < points.length - 1) {
          element.id = index + 1
        }
        if (index == 0) {
          newPoint.id = this.selectedDevice.id
          isViewPoint = true
        } else if (index === points.length - 1) {
          newPoint.id = this.selectedDevice.networkSwitchLinkId
          isViewPoint = true
        } else {
          newPoint.id = element.index
        }
        this.createPoint(this.selectedDevice, element.index, element.x, element.y, newPoint.id, isViewPoint, newPoint);
      });
      if (this.domDraggingNode !== undefined) {
        this.domDraggingNode.dispatchEvent(new MouseEvent('mousedown'));
        if (this.domDraggingNode) {
          this.domDraggingNode.addEventListener('mouseup', (e: any) => {
            e.preventDefault();
            const tmpUpdateCoordiantes: any = { cableCoordinates: _.cloneDeep(PointStore.getAllPoints()) }
            this.updateConnection.emit(_.cloneDeep(tmpUpdateCoordiantes));
            if (this.domDraggingNode !== undefined) {
              this.domDraggingNode.removeEventListener('mouseup', (e: any) => { });
            }
          })
        }
        const timeOut = setTimeout(() => {
          this.domDraggingNode = undefined;
          clearTimeout(timeOut);
        }, 0);

      }
      this.selectedDevice.cableCoordinates = _.cloneDeep(PointStore.getAllPoints());
      if (this.selectedDevice.id === this.device.id) {
        this.device.cableCoordinates = this.selectedDevice.cableCoordinates;
      } else if (this.device.childFloorEquipments && this.device.childFloorEquipments.filter((e: any) => e.id === this.selectedDevice.id).length > 0) {
        this.device.childFloorEquipments.filter((e: any) => e.id === this.selectedDevice.id)[0].cableCoordinates = this.selectedDevice.cableCoordinates
      }
    });
  }
  updateCableCoordinates(isEmit: boolean = false) {
    return new Promise((resolve: any, reject: any) => {
      if (isEmit) {
        const tmpUpdateCoordiantes: any = { cableCoordinates: this.selectedDevice.cableCoordinates }
        this.updateConnection.emit(_.cloneDeep(tmpUpdateCoordiantes));
      }
      resolve(true);
    })
  }
}
