<div *ngIf="isFavorite || (equipmentDetails && equipmentDetails.type ==='task')" class="filter-menu equipment-sidebar"
    (click)="$event.stopPropagation();" [ngClass]="{'read-only-form': !isEdit}">
    <div class="filter-menu equipment-sidebar" soDragDropFileUpload (filesChangeEmiter)="onFilesChange($event)"
        (filesInvalidEmiter)="onFileInvalids($event)" [allowedExtensions]="['jpg', 'jpeg', 'png']" [isViewOnly]="isEdit"
        [ngClass]="{'read-only-form': 
    !isEdit}">
        <form [formGroup]="equipmentDetailsForm" style="cursor: default;" novalidate>
            <div class="filter-title">
                <h2 class="d-flex align-items-center">
                    <span *ngIf="isFavorite;else favoriteData" class="d-flex align-items-center">
                        <span class="device-label"
                            *ngIf="favoriteEquipmentDetails.type === deviceType.Task;else notTask">
                        </span>
                        <ng-template #notTask>
                        </ng-template>
                        <em class="{{favoriteEquipmentDetails.equipment.iconName}} "
                            [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        <span [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"
                            *ngIf="!fromProject" class="device-info-text equipement-title" title="{{equipmentDetailsForm.value.name || equipmentDetailsForm.value.name ||
             equipmentDetailsForm.value.typeName }}"> {{equipmentDetailsForm.value.partNumber ||
                            equipmentDetailsForm.value.name || equipmentDetailsForm.controls['typeName'].value}}
                        </span>
                        <span [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"
                            *ngIf="fromProject" class="device-info-text equipement-title" title="{{equipmentDetailsForm.value.name || equipmentDetailsForm.value.name ||
                         equipmentDetailsForm.value.typeName }}">{{ equipmentDetailsForm.value.name ||
                            equipmentDetailsForm.controls['typeName'].value}}
                        </span>
                        <em [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"
                            class="fas fa-star fav-icon" title="Favorite"></em>

                    </span>
                    <ng-template #favoriteData>
                        <em class="fas fa-flag flag-icon" title="Flagged"
                            *ngIf="equipmentDetails.isFlag && fromProject"></em>
                        <span class="device-label" *ngIf="equipmentDetails.type === deviceType.Task;else notTask">
                            <em class="{{equipmentDetails.equipment.iconName}}"
                                [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        </span>
                        <ng-template #notTask>
                            <!-- device icon dynamic-->
                            <em class="{{equipmentDetails.equipment.iconName}}"
                                [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        </ng-template>
                        <span class="equipement-title"
                            [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"
                            title="{{equipmentDetailsForm.controls['name'].value}}">{{equipmentDetailsForm.controls['name'].value
                            }}</span>
                    </ng-template>
                    <a href="javascript:void(0)" class="filter-close" (click)="closeEquipmentDetail();">
                        <em class="fas fa-arrow-left"
                            [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        <span></span>
                    </a>
                </h2>
            </div>
            <div class="filter-tabs"
                [ngClass]="{'project-filter-tabs': (fromProject && (equipmentDetails && (equipmentDetails.type !== 'device' || !equipmentDetails.isHub))),'site-filter-tabs':!fromProject ,'project-archive-filter-tabs':fromProject && (equipmentDetails && equipmentDetails.type !== 'device') && (equipmentDetails && (equipmentDetails.isArchived || buildingDetails.isProjectArchived)) && equipmentDetails && equipmentDetails.projectId !== null, 'project-site-device-tabs': fromProject && equipmentDetails && equipmentDetails.projectId === null && !equipmentDetails.isHub}">

                <!--Task Form start-->
                <tabset class="task-tab"
                    *ngIf="equipmentDetails && equipmentDetails.type === deviceType.Task && !isFavorite">
                    <tab>
                        <so-equipment-details-label [fromProject]="fromProject" [equipmentDetails]="equipmentDetails"
                            [isLiveSystemAccessForCustomer]="isLiveSystemAccessForCustomer"
                            [buildingDetails]="buildingDetails"
                            [publishedTooltipMessageText]="publishedTooltipMessageText"></so-equipment-details-label>
                        <div *ngIf="equipmentDetails.type === deviceType.Task">

                            <div class="flag flag-bg" *ngIf="equipmentDetails.isFlag && fromProject">
                                <span class="remove-flag" (click)="removeFlag()"
                                    *ngIf="!equipmentDetails.isArchived && equipmentDetailsForm.controls.flagNotes.enabled && isEdit">
                                    <em class="far fa-flag"></em>
                                    {{(equipmentDetails && equipmentDetails.flagCreatedBy!==null) ? 'Resolve
                                    Flag':'Remove Flag'}}

                                </span>
                                <div class="form-group"
                                    *ngIf="equipmentDetailsForm.controls.flagNotes.enabled && !equipmentDetails.isArchived && isEdit; else disablededFlag">
                                    <label>Flag Note <sup>*</sup></label>
                                    <textarea maxlength="4000" class="form-control flag-textarea"
                                        placeholder="Flag Note" formControlName="flagNotes" (input)="onInput($event)"
                                        (blur)="saveDetails(false)"></textarea>
                                    <so-control-messages [name]="'Flag Note'"
                                        [control]="equipmentDetailsForm.controls.flagNotes">
                                    </so-control-messages>
                                </div>
                                <ng-template #disablededFlag>
                                    <div class="form-group">
                                        <label>Flag Note</label>
                                        <p class="form-control comment-desc linkified-textarea">
                                            <span *ngIf="equipmentDetailsForm.controls.flagNotes.value === null"
                                                class="placeholder">Flag
                                                Note</span>
                                            <span
                                                [innerHTML]="equipmentDetailsForm.controls.flagNotes.value | linkify:optionsE">

                                            </span>
                                        </p>
                                    </div>
                                </ng-template>
                                <div class="date-format form-group filter-date clearfix"
                                    *ngIf="equipmentDetails.flagCreatedBy">
                                    <div class="created">
                                        <label>Flagged:</label>
                                        <div class="created-by"
                                            title="{{equipmentDetails.flagCreatedDateTime| date : 'M/dd/yy, h:mm a'}}">
                                            {{flaggedTime}}
                                        </div>
                                        <div class="created-by" (mouseout)="out()"
                                            (mouseover)="fillToolTipObj($event,equipmentDetails.flagCreatedBy)">
                                            <div class="custom-tooltip flag-tooltip">
                                                &nbsp;{{equipmentDetails.flagCreatedBy.name}} {{equipmentDetails.flagCreatedBy.isActive === 0 ? ' (inactive)' : ''}}
                                                <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Task Name&nbsp;<sup>*</sup>
                                    <em *ngIf="equipmentDetails.parentId===null && countDuplicateName>1 && !equipmentDetails.isArchived"
                                        class="fas fa-warning yellow-text cursor-pointer"
                                        title="There {{countDuplicateName === 2 ? 'is' : 'are'}} {{countDuplicateName-1}} other {{countDuplicateName === 2 ? (fromProject ? 'Device/Task' : 'Device') : (fromProject ? 'Devices/Tasks' : 'Devices')}} with the same name on this Plan"></em>
                                </label>
                                <input maxlength="80" type="text" class="form-control" placeholder="Task Name"
                                    formControlName="name" (input)="onInput($event)" (blur)="saveDetails(true)">
                                <so-control-messages [name]="'Name'" [control]="equipmentDetailsForm.controls.name">
                                </so-control-messages>
                            </div>
                            <div class="form-group">
                                <label>Abbreviated Name&nbsp;</label>
                                <em *ngIf="equipmentDetails.parentId===null && countDuplicateAbbreviatedName>1 && !equipmentDetails.isArchived"
                                    class="fas fa-warning yellow-text cursor-pointer"
                                    title="There {{countDuplicateAbbreviatedName === 2 ? 'is' : 'are'}} {{countDuplicateAbbreviatedName-1}} other {{countDuplicateAbbreviatedName === 2 ? (fromProject ? 'Device/Task' : 'Device') : (fromProject ? 'Devices/Tasks' : 'Devices')}} with the same Abbreviated Name on this Plan"></em>
                                <input maxlength="80" type="text" class="form-control" placeholder="Abbreviated Name"
                                    formControlName="abbreviatedName" (input)="onInput($event)"
                                    (blur)="saveDetails(true)">
                                <so-control-messages [name]="'Abbreviated Name'"
                                    [control]="equipmentDetailsForm.controls.abbreviatedName">
                                </so-control-messages>
                            </div>
                            <div class="form-group">
                                <label>Task Type</label>
                                <input type="text" class="form-control" [attr.disabled]="true" placeholder="Device Type"
                                    formControlName="typeName" (blur)="saveDetails(true)" focus>
                            </div>

                            <div class="form-group device-color-form-group" *ngIf="pinColorType === 'Device Color'"
                                [ngClass]="{'not-allowed' : isFormDisabled}">
                                <label>Device Color </label>
                                <div>

                                    <div class="d-flex mb-1">
                                        <label class="device-color-label">System Type</label>
                                        <div class="pin-color me-2"
                                            (click)="changePinColor(equipmentDetails.equipment.pinColor)" [ngClass]="{'active': (equipmentDetails.equipment.pinColor ===
                  equipmentDetailsForm.get('pinColor').value)? true : false}"
                                            [ngStyle]="{'background-color': equipmentDetails.equipment.pinColor}">
                                        </div>
                                        <label>or</label>
                                    </div>
                                    <div class="d-flex">
                                        <label class="device-color-label">Custom Color</label>
                                        <ul class="custom-color-panel">
                                            <li *ngFor="let color of codeForDeviceColor"
                                                (click)="!isFormDisabled ? changePinColor(color.colorCode) : ''"
                                                [ngClass]="{'not-allowed' : isFormDisabled}">
                                                <div class="pin-color"
                                                    [ngClass]="{'active': color.colorCode === equipmentDetailsForm.controls.pinColor.value}"
                                                    [ngStyle]="{'background-color': color.colorCode}">
                                                    <input type="hidden" class="form-control"
                                                        formControlName="pinColor">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="fromProject">
                                <div class="form-group">
                                    <label>Status</label>
                                    <div *ngIf="isEdit && !equipmentDetails?.isArchived;else projectReadOnly ">
                                        <div class="btn-group btn-status-group">
                                            <label
                                                [ngClass]="{'active': equipmentDetailsForm.value.installStatus === 'Planned'}"
                                                class="btn NotWorking" (click)="setInstallStatus('Planned')">
                                                <span class="inner-btn">
                                                    <em>Planned</em>
                                                </span>
                                            </label>
                                            <label
                                                [ngClass]="{'active': equipmentDetailsForm.value.installStatus === 'InProgress'}"
                                                class="btn OperationalWithIssue"
                                                (click)="setInstallStatus('InProgress')">
                                                <span class="inner-btn">
                                                    <em>In Progress</em>
                                                </span>
                                            </label>
                                            <label
                                                [ngClass]="{'active': equipmentDetailsForm.value.installStatus === 'Installed'}"
                                                class="btn Operational" (click)="setInstallStatus('Installed')">
                                                <span class="inner-btn">
                                                    <em>Done</em>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <ng-template #projectReadOnly>
                                        <div class="btn-group btn-status-group">
                                            <label [ngStyle]="{'cursor':'not-allowed'}" [ngClass]="{'active':
                    equipmentDetailsForm.controls['installStatus'].value ===
                    'Planned'}" class="btn NotWorking">
                                                <span class="inner-btn">
                                                    <em>Planned</em>
                                                </span>
                                            </label>
                                            <label [ngStyle]="{'cursor':'not-allowed'}"
                                                [ngClass]="{'active': equipmentDetailsForm.controls['installStatus'].value === 'InProgress'}"
                                                class="btn OperationalWithIssue">
                                                <span class="inner-btn">
                                                    <em>In Progress</em>
                                                </span>
                                            </label>

                                            <label [ngStyle]="{'cursor':'not-allowed'}"
                                                [ngClass]="{'active': equipmentDetailsForm.controls['installStatus'].value === 'Installed'}"
                                                class="btn Operational">
                                                <span class="inner-btn">
                                                    <em>Done</em>
                                                </span>
                                            </label>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="form-group"
                                *ngIf="equipmentDetailsForm.controls.description.enabled; else disablededDesc">
                                <label>Description</label>
                                <textarea maxlength="4000" class="form-control" placeholder="Description"
                                    formControlName="description" (blur)="saveDetails(true)"></textarea>
                            </div>
                            <ng-template #disablededDesc>
                                <div class="form-group">
                                    <label>Description</label>
                                    <p class="form-control comment-desc linkified-textarea">
                                        <span *ngIf="equipmentDetailsForm.controls.description.value === null"
                                            class="placeholder">Description</span>
                                        <span
                                            [innerHTML]="equipmentDetailsForm.controls.description.value | linkify:optionsE">

                                        </span>
                                    </p>

                                </div>
                            </ng-template>
                            <div class="form-group"
                                *ngIf="isEdit && fromProject && equipmentDetails.projectId !== null; else disablededInstructionsDesc">
                                <label>Instructions</label>
                                <so-textarea [disabled]="(fromProject &&
                        !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                        null) ? true : false" [maxLength]="4000" [placeHolder]="'Instructions'"
                                    (input)="onInput($event)" (blur)="saveDetails(false)"
                                    [inputControlName]="$any(equipmentDetailsForm).controls['instructions']"></so-textarea>
                                <so-control-messages [name]="'instructions'"
                                    [control]="equipmentDetailsForm.controls.instructions">
                                </so-control-messages>
                            </div>
                            <ng-template #disablededInstructionsDesc>
                                <div class="form-group" *ngIf="fromProject && equipmentDetails.projectId !== null">
                                    <label>Instructions</label>
                                    <p class="form-control comment-desc linkified-textarea">
                                        <span
                                            *ngIf="equipmentDetailsForm.controls.instructions.value === null || equipmentDetailsForm.controls.instructions.value === ''"
                                            class="placeholder">Instructions</span>
                                        <span
                                            [innerHTML]="equipmentDetailsForm.controls.instructions.value | linkify:optionsE">

                                        </span>
                                    </p>
                                </div>
                            </ng-template>

                            <div class="form-group" *ngIf="isEdit && !(fromProject &&
!equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
null); else disablededInstallDesc">
                                <label>Field Notes</label>
                                <so-textarea [disabled]="(fromProject &&
    !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
    null) ? true : false" [maxLength]="4000" [placeHolder]="'Field Notes'" (input)="onInput($event)"
                                    (blur)="saveDetails(false)"
                                    [inputControlName]="$any(equipmentDetailsForm).controls['installationNotes']"></so-textarea>
                                <so-control-messages [name]="'installationNotes'"
                                    [control]="equipmentDetailsForm.controls.installationNotes">
                                </so-control-messages>
                            </div>
                            <ng-template #disablededInstallDesc>
                                <div class="form-group">
                                    <label>Field Notes</label>
                                    <p class="form-control comment-desc linkified-textarea">
                                        <span *ngIf="equipmentDetailsForm.controls.installationNotes.value === null"
                                            class="placeholder">Field Notes</span>
                                        <span
                                            [innerHTML]="equipmentDetailsForm.controls.installationNotes.value | linkify:optionsE">

                                        </span>
                                    </p>
                                </div>
                            </ng-template>
                            <div class="form-group default-view mb-0"
                                [ngStyle]="{'min-height':isCompressing ? '200px' : ''}">
                                <so-loader *ngIf="isCompressing" [tableloader]="true"></so-loader>
                                <label>Images</label>
                                <div class="row dragged-invalid-file">
                                    <div class="col-sm-10">
                                        <div *ngIf="isEdit && !isFormDisabled" class=" file-uploaded clearfix">
                                            <so-file-upload (click)="$event.stopPropagation()"
                                                [uploadedFiles]="uploadedFile" [isShowFileIcon]="true"
                                                (uploadedFilesChange)="fileUploaded($event)"
                                                [fileUploadOptions]="fileUploadOptions"
                                                (invalidFileDragged)="fileUploadFail()"
                                                [hideFileValidationMessage]="hideFileValidationMessage">
                                            </so-file-upload>
                                        </div>
                                    </div>

                                    <div class="col-sm-2">
                                        <span *ngIf="isEdit && !isFormDisabled" class="text-center btn-camera"
                                            title="Take Picture" (click)="openCameraPopup()">
                                            <em class="fa fa-camera"></em>
                                        </span>
                                    </div>
                                    <div>
                                        <div *ngIf="invalidFileDragged" class="help-block text-danger browse-invalid">
                                            {{limitErrorMessage}}</div>
                                    </div>
                                </div>
                                <!-- Displaying Image Section Start -->
                                <div class="equipment-thumb-block" *ngIf="hasImage; else noImage">
                                    <div *ngFor="let equipImg of uploadedFiles | imageGroupBy: 'stage'; trackBy: detailsTrackByStage"
                                        class="d-inline-block w-100 mb-3">
                                        <div *ngIf="!equipImg.isDeleted">
                                            <label *ngIf="equipImg.showImageSection">
                                                {{equipImg.key === '' ? 'Images without Stage' : equipImg.key + '' +'
                                                Images'}}
                                            </label>
                                            <ng-container
                                                *ngFor="let equipImgDate of equipImg.value | imageGroupBy: 'createdDateTime'; trackBy: detailsTrackByDateTime">
                                                <div class="d-inline-block w-100 testignew"
                                                    *ngIf="equipImgDate.showDateSection">
                                                    <div class="d-inline-block images-upload-date mb-1">
                                                        {{equipImgDate.key}}
                                                    </div>
                                                    <div>
                                                        <ng-container
                                                            *ngFor="let imgFile of equipImgDate.value; let index = index;trackBy: detailsTrackBy">
                                                            <div class="equipment-thumb-inner"
                                                                *ngIf="!imgFile.isDeleted">
                                                                <div>
                                                                    <div class="div-table">
                                                                        <span *ngIf="isEdit && !isFormDisabled"
                                                                            class="fa fa-times img-thumb-remove"
                                                                            (click)="$event.stopPropagation();openImageDeletePopup(imgFile,index)">
                                                                        </span>
                                                                        <div class="div-table-cell">
                                                                            <div class="equipment-thumb-img">
                                                                                <div *ngIf="imgFile.isLoading">
                                                                                    <so-loader
                                                                                        [smallloader]='true'></so-loader>
                                                                                </div>
                                                                                <img alt=""
                                                                                    *ngIf="imgFile.thumbnailAwsImageURL"
                                                                                    [appRetryImage]="imgFile.thumbnailAwsImageURL"
                                                                                    [src]="imgFile.thumbnailAwsImageURL"
                                                                                    class=" fallback img-responsive"
                                                                                    (click)="$event.stopPropagation();openImagePopup(imgFile, index)"
                                                                                    soImgOrientation
                                                                                    [isFloorPlanDevices]="true"
                                                                                    [isImageLoaded]="imgFile.isLoading"
                                                                                    (load)="toggleImageLoader(imgFile, false)"
                                                                                    (error)="toggleImageLoader(imgFile, false)"
                                                                                    (loaderOff)="toggleImageLoader(imgFile, false)"
                                                                                    [parent]="'equipment-thumb-img'"
                                                                                    [index]="index" [apply]="imgFile">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="equipment-caption"
                                                                        (click)="$event.stopPropagation();openImagePopup(imgFile, index)"
                                                                        *ngIf="imgFile.fileName">
                                                                        <a style="cursor:pointer"
                                                                            title="{{imgFile.fileName}}">{{imgFile.fileName}}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #noImage>
                                    <div class="mb-3">
                                        <!-- No images available -->
                                        Drop image, take picture or browse
                                    </div>
                                </ng-template>
                                <!-- Displaying Image Section End -->
                            </div>
                            <div class="form-group" *ngIf="fromProject && equipmentDetails.projectId !== null">
                                <label>Assigned To</label>
                                <select class="form-control" [attr.disabled]="(fromProject &&
                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                            null) ? '' : null" formControlName="assignedToUser" (change)="saveDetails(true)">
                                    <option *ngFor="let item of installedByUsers"
                                        [ngStyle]="{'display': item.isRemoved? 'none': 'block'}" [ngValue]="item.key">
                                        {{item.value}} {{item && item.isRemoved ? '(Inactive)':''}}</option>
                                </select>
                                <so-control-messages [name]="'Assigned To'"
                                    [control]="equipmentDetailsForm.controls.assignedToUser">
                                </so-control-messages>
                            </div>


                            <div class="form-group" *ngIf="fromProject && equipmentDetails.projectId !== null">
                                <label>Date Due</label>
                                <so-datepicker [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY',showWeek: false }"
                                    (datePickerChange)="saveDetails(false)"
                                    [inputControlName]="$any(equipmentDetailsForm).controls['dueDate']" [disabled]="!isEdit || isFormDisabled || (fromProject &&
                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                            null) ? true : false" [id]="'dueDate'"></so-datepicker>
                            </div>

                            <div class="form-group">
                                <label>Date Completed</label>
                                <so-datepicker [disabled]="!isEdit || isFormDisabled || (fromProject &&
                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                            null) ? true : false" [id]="'dateInstalled'" (datePickerChange)="saveDetails(false)"
                                    [inputControlName]="$any(equipmentDetailsForm).controls['installationDate']"></so-datepicker>
                            </div>

                            <div class="form-group">
                                <label>Completed By</label>
                                <select class="form-control" formControlName="installedByUser"
                                    (change)="saveDetails(true)" [attr.disabled]="(fromProject &&
          !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
          null) ? '' : null">
                                    <option *ngFor="let item of installedByUsers"
                                        [ngStyle]="{'display': item.isRemoved? 'none': 'block'}" [ngValue]="item.key">
                                        {{item.value}} {{item && item.isRemoved ? '(Inactive)':''}}</option>
                                </select>
                            </div>

                            <div class="form-group device-group install-hours"
                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                <label>Budgeted Hours</label>
                                <so-textbox [maxLength]="5" [placeHolder]="'3.5'" (blur)="saveDetails(false)"
                                    (input)="onInput($event)" [customDirective]="true"
                                    [inputControlName]="$any(equipmentDetailsForm).controls['installHours']"></so-textbox>
                                <so-control-messages [name]="'Budgeted Hours'"
                                    [control]="equipmentDetailsForm.controls.installHours">
                                </so-control-messages>
                            </div>
                            <div class="form-group price-group replace-cost-group"
                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                <label>Budgeted Cost</label>
                                <div>
                                    <span>$</span>
                                    <so-textbox [maxLength]="15" [placeHolder]="'0.00'" (blur)="saveDetails(false)"
                                        (input)="onInput($event)" [customDirective]="true"
                                        [inputControlName]="$any(equipmentDetailsForm).controls['budgetedCost']"></so-textbox>
                                    <so-control-messages [name]="'Budgeted Cost'"
                                        [control]="equipmentDetailsForm.controls.budgetedCost">
                                    </so-control-messages>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Priority</label>
                                <select class="form-control" formControlName="priority" (change)="saveDetails(true)"
                                    [attr.disabled]="(fromProject && !equipmentDetails.isPublishedToAMT &&
              !equipmentDetails.isArchived && equipmentDetails.projectId === null) ? '' : null">
                                    <option *ngFor="let item of priorityItems" [ngValue]="item.value">{{item.key}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group"
                                *ngIf="fromProject && equipmentDetails.projectId !== null && !equipmentDetails.isNew &&
                  (permissions.canManage || permissions.canUpdate || isEdit || ((isGlobalUser && (isProjectManager)) || (isGlobalAdmin  ) ))   ">
                                <div class="project-name">
                                    <a href="javascript:void(0)" (click)="deviceHistory()">Task History</a>
                                </div>
                            </div>
                            <div *ngIf="!equipmentDetails.isNew">
                                <div class="date-format form-group filter-date clearfix">
                                    <div class="created">
                                        <label>Created:</label>
                                        <div class="created-by"
                                            title="{{equipmentDetails.createdDateTime| date : 'M/dd/yy, h:mm a'}}">
                                            {{createdTime}}
                                        </div>
                                        <div class="created-by" (mouseout)="out()"
                                            (mouseover)="fillToolTipObj($event,equipmentDetails.createdByUser)">
                                            <div class="custom-tooltip">
                                                {{equipmentDetails.createdBy}} {{equipmentDetails.createdByUser.isActive === 0 ? ' (inactive)' : ''}}
                                                <so-tool-tip *ngIf="toolTipObj" [toolTipObj]="toolTipObj"></so-tool-tip>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="date-format form-group filter-date  clearfix">
                                    <div class="created">
                                        <label>Updated:</label>
                                        <div class="created-by"
                                            title="{{this.equipmentDetails.modifiedDateTime | date : 'M/dd/yy, h:mm a'}}">
                                            {{updatedTime}}</div>
                                        <div class="created-by" (mouseout)="out()"
                                            (mouseover)="fillToolTipObjUpdate($event,equipmentDetails.updatedByUser)">
                                            <div class="custom-tooltip">
                                                {{equipmentDetails.updatedBy}} {{equipmentDetails.updatedByUser.isActive === 0 ? ' (inactive)' : ''}}
                                                <so-tool-tip [toolTipObj]="toolTipObjUpdate"></so-tool-tip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!equipmentDetails.isNew && !equipmentDetails.isArchived"
                                    (click)="createDeviceLink()" class="device-shareable-link">
                                    <em class="fas fa-link icon-shareable-link"></em> Shareable Link
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>

                <!--Task Form end-->

                <!--Device Favorite Form start-->
                <div *ngIf="isFavorite && favoriteEquipmentDetails && favoriteEquipmentDetails.type==='device'"
                    class="favourite-device-info">
                    <div class="form-group device-group">
                        <label>Favorite Device Name <sup>*</sup></label>
                        <input maxlength="75" type="text" class="form-control" placeholder="Favorite Device Name"
                            formControlName="name" (blur)="saveDetails(true)" (input)="onInput($event)">
                        <so-control-messages [name]="'Name'"
                            [control]="equipmentDetailsForm.controls.name"></so-control-messages>
                    </div>
                    <div class="form-group device-group">
                        <label>Favorite Abbreviated Name</label>
                        <input maxlength="80" type="text" class="form-control" placeholder="Favorite Abbreviated  Name"
                            formControlName="abbreviatedName" (blur)="saveDetails(true)" (input)="onInput($event)">
                    </div>
                    <div class="form-group">
                        <label>System Type</label>
                        <input type="text" class="form-control" [attr.disabled]="true" placeholder="System Type"
                            formControlName="categoryName" (blur)="saveDetails(true)" focus (input)="onInput($event)">
                    </div>
                    <div class="form-group">
                        <label>Device Type</label>
                        <input type="text" [attr.disabled]="true" class="form-control" placeholder="Device Type"
                            formControlName="typeName" (blur)="saveDetails(true)" focus (input)="onInput($event)">
                    </div>
                    <div class="form-group device-color-form-group " *ngIf="pinColorType === 'Device Color'  && isEdit"
                        [ngClass]="{'not-allowed' : isFormDisabled}">
                        <label>Device Color</label>
                        <div class="d-flex mb-1">
                            <label class="device-color-label">System Type</label>
                            <div class="pin-color me-2"
                                (click)="changeFavPinColor(favoriteEquipmentDetails.equipment.pinColor)" [ngClass]="{'not-allowed': isFormDisabled,'active': (favoriteEquipmentDetails.equipment.pinColor ===
              equipmentDetailsForm.get('pinColor').value)? true : false}"
                                [ngStyle]="{'background-color': favoriteEquipmentDetails.equipment.pinColor}">
                            </div>
                            <label>or</label>
                        </div>
                        <div class="d-flex">
                            <label class="device-color-label">Custom Color</label>
                            <ul class="custom-color-panel">
                                <li *ngFor="let color of codeForDeviceColor"
                                    (click)="changeFavPinColor(color.colorCode)">
                                    <div class="pin-color"
                                        [ngClass]="{'active': color.colorCode === equipmentDetailsForm.controls.pinColor.value, 'not-allowed': isFormDisabled}"
                                        [ngStyle]="{'background-color': color.colorCode}">
                                        <input type="hidden" class="form-control" formControlName="pinColor">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Part Number</label>
                        <input maxlength="80" type="text" class="form-control" placeholder="Part Number"
                            formControlName="partNumber" (blur)="saveDetails(true)" (input)="onInput($event)">
                        <so-control-messages [name]="'Part Number'"
                            [control]="equipmentDetailsForm.controls.partNumber">
                        </so-control-messages>
                    </div>
                    <div class="form-group">
                        <label>Manufacturer</label>
                        <input maxlength="80" type="text" class="form-control" placeholder="Manufacturer"
                            formControlName="manufacturer" (blur)="saveDetails(true)" (input)="onInput($event)">
                        <so-control-messages [name]="'Manufacturer'"
                            [control]="equipmentDetailsForm.controls.manufacturer">
                        </so-control-messages>
                    </div>
                    <div class="form-group device-group install-hours" *ngIf="fromProject">
                        <label>Budgeted Hours</label>
                        <so-textbox [maxLength]="5" [placeHolder]="'3.5'" (blur)="saveDetails(true)"
                            (input)="onInput($event)" [customDirective]="true"
                            [inputControlName]="$any(equipmentDetailsForm).controls['installHours']"></so-textbox>
                        <so-control-messages [name]="'Budgeted Hours'"
                            [control]="equipmentDetailsForm.controls.installHours">
                        </so-control-messages>
                    </div>
                    <div class="form-group price-group replace-cost-group" *ngIf="fromProject">
                        <label>Budgeted Cost</label>
                        <div>
                            <span>$</span>
                            <so-textbox [maxLength]="15" [placeHolder]="'0.00'" (blur)="saveDetails(true)"
                                (input)="onInput($event)" [customDirective]="true"
                                [inputControlName]="$any(equipmentDetailsForm).controls['budgetedCost']"></so-textbox>
                            <so-control-messages [name]="'Budgeted Cost'"
                                [control]="equipmentDetailsForm.controls.budgetedCost">
                            </so-control-messages>
                        </div>
                    </div>
                    <div class="form-group price-group replace-cost-group">
                        <label>Replacement Cost</label>
                        <div>
                            <span>$</span>
                            <so-textbox [maxLength]="15" [placeHolder]="'0.00'" (blur)="saveDetails(true)"
                                (input)="onInput($event)" [customDirective]="true"
                                [inputControlName]="$any(equipmentDetailsForm).controls['price']"></so-textbox>
                            <so-control-messages [name]="'Replacement Cost'"
                                [control]="equipmentDetailsForm.controls.price">
                            </so-control-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>IP / Analog</label>
                        <select class="form-control" formControlName="dataType" (change)="saveDetails(true);">
                            <option value="null">None</option>
                            <option *ngFor="let item of equipmentIPTypeList" [ngValue]="item">{{item}}</option>
                        </select>
                        <so-control-messages [name]="'IP / Analog'" [control]="equipmentDetailsForm.controls.dataType">
                        </so-control-messages>
                    </div>
                    <div class="form-group">
                        <label>Interior / Exterior</label>
                        <select class="form-control" formControlName="deviceLocation" (change)="saveDetails(true);">
                            <option value="null">Not Set</option>
                            <option *ngFor="let item of equipmentLocationList" [ngValue]="item">{{item}}</option>
                        </select>
                        <so-control-messages [name]="'Device Location'"
                            [control]="equipmentDetailsForm.controls.deviceLocation">
                        </so-control-messages>
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea maxlength="4000" class="form-control" placeholder="Description"
                            formControlName="description" (blur)="saveDetails(true)"
                            (input)="onInput($event)"></textarea>
                        <so-control-messages [name]="'Description'"
                            [control]="equipmentDetailsForm.controls.description">
                        </so-control-messages>
                    </div>

                    <div class="favoriteEquipmentSecondary" *ngIf="favChildCount > 0">
                        <h3 class="secondary-title">Secondary Devices</h3>
                        <ul>
                            <li
                                *ngFor="let child of favoriteEquipmentDetails.childFavouriteEquipments | filterFavDeletedDevice:fromProject; let cindex = index">
                                <div (contextmenu)="contextClickFav($event,child)"
                                    class="d-flex align-items-center justify-content-between">
                                    <div class="pe-1">
                                        <span class="icon" [ngClass]="child.equipment.iconName"></span>
                                    </div>
                                    <div class="flex-grow-1 text-truncate flex-fill">
                                        <span class="text d-flex"
                                            (click)="$event.stopPropagation();openEquipmentDetailForFav({parent:favoriteEquipmentDetails,child:child,cindex:cindex})">
                                            <label class="d-flex text-nowrap">Name: &nbsp; </label>
                                            <span class="d-inline-block text-truncate pe-2" title="{{child.name
                                || child.typeName}}">{{child.name
                                                || child.typeName}}</span></span>

                                        <span class="text d-flex"
                                            *ngIf="child.type === 'device' && child.partNumber !== null && child.partNumber !== '' "
                                            (click)="$event.stopPropagation();openEquipmentDetailForFav({parent:favoriteEquipmentDetails,child:child,cindex:cindex})">
                                            <label class="d-flex text-nowrap">Part #: &nbsp;</label>
                                            <span class="d-inline-block text-truncate pe-2"
                                                title="{{child.partNumber}}">
                                                {{child.partNumber}} </span>
                                        </span>
                                    </div>
                                    <div> <span class="fas fa-trash-alt cursor-pointer"
                                            *ngIf="permissions.canManage && isEdit"
                                            (click)="$event.stopPropagation();showEquipmentModalForFavorite(child)"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--Device Favorite Form end-->

                <!--Task Favorite Form start-->
                <div *ngIf="isFavorite && favoriteEquipmentDetails && favoriteEquipmentDetails.type==='task'"
                    class="favourite-device-info">
                    <div class="form-group device-group">
                        <label>Favorite Task Name <sup>*</sup></label>
                        <input maxlength="80" type="text" class="form-control" placeholder="Favorite Task Name"
                            formControlName="name" (blur)="saveDetails(true)" (input)="onInput($event)">
                        <so-control-messages [name]="'Name'"
                            [control]="equipmentDetailsForm.controls.name"></so-control-messages>
                    </div>

                    <div class="form-group device-group">
                        <label>Favorite Abbreviated Name</label>
                        <input maxlength="75" type="text" class="form-control" placeholder="Favorite Abbreviated  Name"
                            formControlName="abbreviatedName" (blur)="saveDetails(true)">
                    </div>
                    <div class="form-group">
                        <label>Task Type</label>
                        <input type="text" class="form-control" [attr.disabled]="true" placeholder="Device Type"
                            formControlName="typeName" (blur)="saveDetails(true)" focus (input)="onInput($event)">
                    </div>
                    <div class="form-group device-color-form-group " *ngIf="pinColorType === 'Device Color' && isEdit"
                        [ngClass]="{'not-allowed' : isFormDisabled}">
                        <label>Device Color</label>
                        <div>

                            <div class="d-flex mb-1">
                                <label class="device-color-label">System Type</label>
                                <div class="pin-color me-2"
                                    [ngClass]="{'active': (favoriteEquipmentDetails.equipment.pinColor ===
                                    equipmentDetailsForm.get('pinColor').value)? true : false, 'not-allowed': isFormDisabled}"
                                    (click)="changeFavPinColor(favoriteEquipmentDetails.equipment.pinColor)"
                                    [ngStyle]="{'background-color': favoriteEquipmentDetails.equipment.pinColor}">
                                </div>
                                <label>or</label>
                            </div>
                            <div class="d-flex">
                                <label class="device-color-label">Custom Color</label>
                                <ul class="custom-color-panel">
                                    <li *ngFor="let color of codeForDeviceColor"
                                        (click)="changeFavPinColor(color.colorCode)">
                                        <div class="pin-color"
                                            [ngClass]="{'active': color.colorCode === equipmentDetailsForm.controls.pinColor.value,'not-allowed': isFormDisabled}"
                                            [ngStyle]="{'background-color': color.colorCode}">
                                            <input type="hidden" class="form-control" formControlName="pinColor">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-group device-group install-hours">
                        <label>Budgeted Hours</label>
                        <so-textbox [maxLength]="5" [placeHolder]="'3.5'" (blur)="saveDetails(false)"
                            (input)="onInput($event)" [customDirective]="true"
                            [inputControlName]="$any(equipmentDetailsForm).controls['installHours']"></so-textbox>
                        <so-control-messages [name]="'Budgeted Hours'"
                            [control]="equipmentDetailsForm.controls.installHours">
                        </so-control-messages>
                    </div>
                    <div class="form-group price-group replace-cost-group" *ngIf="fromProject">
                        <label>Budgeted Cost</label>
                        <div>
                            <span>$</span>
                            <so-textbox [maxLength]="15" [placeHolder]="'0.00'" (blur)="saveDetails(true)"
                                (input)="onInput($event)" [customDirective]="true"
                                [inputControlName]="$any(equipmentDetailsForm).controls['budgetedCost']"></so-textbox>
                            <so-control-messages [name]="'Budgeted Cost'"
                                [control]="equipmentDetailsForm.controls.budgetedCost">
                            </so-control-messages>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea maxlength="4000" class="form-control" placeholder="Description"
                            formControlName="description" (blur)="saveDetails(true)"
                            (input)="onInput($event)"></textarea>
                        <so-control-messages [name]="'Description'"
                            [control]="equipmentDetailsForm.controls.description">
                        </so-control-messages>
                    </div>

                    <div class="favoriteEquipmentSecondary" *ngIf="favChildCount > 0">
                        <h3 class="secondary-title">Secondary Tasks</h3>
                        <ul>
                            <li
                                *ngFor="let child of favoriteEquipmentDetails.childFavouriteEquipments | filterFavDeletedDevice:fromProject; let cindex = index">
                                <div (contextmenu)="contextClickFav($event,child)"
                                    class="d-flex align-items-center justify-content-between">
                                    <div class="pe-1">
                                        <span class="icon" [ngClass]="child.equipment.iconName"></span>
                                    </div>
                                    <div class="flex-grow-1 text-truncate flex-fill">
                                        <span class="text d-flex"
                                            (click)="$event.stopPropagation();openEquipmentDetailForFav({parent:favoriteEquipmentDetails,child:child,cindex:cindex})">
                                            <label class="d-flex text-nowrap">Name: &nbsp; </label>
                                            <span class="d-inline-block text-truncate pe-2" title="{{child.name
                                    || child.typeName}}">{{child.name
                                                || child.typeName}}</span></span>
                                        <ng-template #partNumber>

                                            {{(child.partNumber===null || child.partNumber === '')? child.partNumber:
                                            child.name
                                            || child.typeName }}

                                        </ng-template><span class="text d-flex"
                                            *ngIf="child.type === 'device' && child.partNumber !== null && child.partNumber !== '' "
                                            (click)="$event.stopPropagation();openEquipmentDetailForFav({parent:favoriteEquipmentDetails,child:child,cindex:cindex})">
                                            <label class="d-flex text-nowrap">Part #: &nbsp;</label>
                                            <span class="d-inline-block text-truncate pe-2"
                                                title="{{child.partNumber}}">
                                                {{child.partNumber}} </span>
                                        </span>
                                    </div>
                                    <span class="fas fa-trash-alt"
                                        [hidden]="!(permissions.canManage && isEdit) || !isEdit"
                                        (click)="$event.stopPropagation();showEquipmentModalForFavorite(child)"></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!--Task Favorite Form end-->
            </div>
        </form>
    </div>
</div>

<div *ngIf="!isFavorite && (equipmentDetails && equipmentDetails.type !=='task')" class="filter-menu equipment-sidebar"
    (click)="$event.stopPropagation();" [ngClass]="{'read-only-form': !isEdit}">
    <div class="filter-menu equipment-sidebar" soDragDropFileUpload (filesChangeEmiter)="onFilesChange($event)"
        (filesInvalidEmiter)="onFileInvalids($event)" [allowedExtensions]="['jpg', 'jpeg', 'png']" [isViewOnly]="isEdit"
        [ngClass]="{'read-only-form': !isEdit}">
        <form [formGroup]="equipmentDetailsForm" style="cursor: default;" novalidate>

            <div class="filter-title">
                <h2 class="d-flex align-items-center">
                    <span *ngIf="isFavorite;else favoriteData" class="d-flex align-items-center">
                        <span class="device-label"
                            *ngIf="favoriteEquipmentDetails.type === deviceType.Task;else notTask">
                        </span>
                        <ng-template #notTask>
                        </ng-template>
                        <em class="{{favoriteEquipmentDetails.equipment.iconName}}"></em>
                        <span *ngIf="!fromProject" class="device-info-text equipement-title" title="{{equipmentDetailsForm.value.name || equipmentDetailsForm.value.name ||
             equipmentDetailsForm.value.typeName }}"> {{equipmentDetailsForm.value.partNumber ||
                            equipmentDetailsForm.value.name || equipmentDetailsForm.controls['typeName'].value}}
                        </span>
                        <span *ngIf="fromProject" class="device-info-text equipement-title" title="{{equipmentDetailsForm.value.name || equipmentDetailsForm.value.name ||
                         equipmentDetailsForm.value.typeName }}">{{ equipmentDetailsForm.value.name ||
                            equipmentDetailsForm.controls['typeName'].value}}
                        </span>
                        <em class="fas fa-star fav-icon" title="Favorite"></em>
                    </span>
                    <ng-template #favoriteData>
                        <em class="fas fa-flag flag-icon" title="Flagged"
                            *ngIf="equipmentDetails.isFlag && fromProject"></em>
                        <span class="device-label" *ngIf="equipmentDetails.type === deviceType.Task;else notTask">
                            <em class="{{equipmentDetails.equipment.iconName}}"></em>
                        </span>


                        <ng-template #notTask>
                            <!-- device icon dynamic-->
                            <em class="{{equipmentDetails.equipment.iconName}}"
                                [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        </ng-template>
                        <span class="equipement-title"
                            [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"
                            title="{{equipmentDetailsForm.controls['name'].value}}">{{equipmentDetailsForm.controls['name'].value
                            }}</span>
                    </ng-template>
                    <a href="javascript:void(0)" class="filter-close" (click)="closeEquipmentDetail();">
                        <em class="fas fa-arrow-left"
                            [@highlightAnimation]="isDeviceSelectionChanged ? 'highlighted' : null"></em>
                        <span></span>
                    </a>
                </h2>
                <em class="far fa-chevrons-down btn-accordian" (click)="openOrCloseAllAccordion()"
                    title=" {{isOpenAll ?  'Collapse All' : 'Expand All'}}" [ngStyle]="{ 'top': topPosition + 'px' }"
                    [ngClass]="{'flagged-device': equipmentDetails.isFlag && fromProject, 'btn-collapse': isOpenAll}"></em>

            </div>
            <div id="device-accordion-panel" (scroll)="onScroll($event)" class="device-accordion-wrapper"
                [ngClass]="{'live-site-device-accordion-wrapper': !fromProject}">

                <div class="filter-tabs"
                    [ngClass]="{'project-filter-tabs': (fromProject && (equipmentDetails && (equipmentDetails.type !== 'device' || !equipmentDetails.isHub))),'site-filter-tabs':!fromProject ,'project-archive-filter-tabs':fromProject && (equipmentDetails && equipmentDetails.type !== 'device') && (equipmentDetails && (equipmentDetails.isArchived || buildingDetails.isProjectArchived)) && equipmentDetails && equipmentDetails.projectId !== null, 'project-site-device-tabs': fromProject && equipmentDetails && equipmentDetails.projectId === null && !equipmentDetails.isHub}">
                    <so-equipment-details-label [fromProject]="fromProject" [equipmentDetails]="equipmentDetails"
                        [isLiveSystemAccessForCustomer]="isLiveSystemAccessForCustomer"
                        [buildingDetails]="buildingDetails"
                        [publishedTooltipMessageText]="publishedTooltipMessageText"></so-equipment-details-label>
                    <div class="flag flag-bg-acdn" id="flagDiv" *ngIf="equipmentDetails.isFlag && fromProject">
                        <span class="remove-flag" (click)="removeFlag()"
                            *ngIf="!equipmentDetails.isArchived && isEdit&& equipmentDetailsForm.controls.flagNotes.enabled">
                            <em class="far fa-flag"></em>
                            {{(equipmentDetails && equipmentDetails.flagCreatedBy!==null) ? 'Resolve Flag':'Remove
                            Flag'}}

                        </span>
                        <div class="form-group"
                            *ngIf="equipmentDetailsForm.controls.flagNotes.enabled && !equipmentDetails.isArchived && isEdit; else disablededFlag">
                            <label>Flag Note<sup>*</sup></label>
                            <textarea maxlength="4000" class="form-control flag-textarea" placeholder="Flag Note"
                                (input)="onInput($event)" (blur)="saveDetails(false)"
                                formControlName="flagNotes"></textarea>
                            <so-control-messages [name]="'Flag Note'"
                                [control]="equipmentDetailsForm.controls.flagNotes">
                            </so-control-messages>
                        </div>

                        <ng-template #disablededFlag>
                            <div class="form-group">
                                <label>Flag Note</label>
                                <p class="form-control comment-desc linkified-textarea">
                                    <span *ngIf="equipmentDetailsForm.controls.flagNotes.value === null"
                                        class="placeholder">Flag
                                        Note</span>
                                    <span
                                        [innerHTML]="equipmentDetailsForm.controls.flagNotes.value | linkify:optionsE">

                                    </span>
                                </p>
                            </div>
                        </ng-template>
                        <div class="date-format form-group filter-date clearfix" *ngIf="equipmentDetails.flagCreatedBy">
                            <div class="created">
                                <label>Flagged:</label>
                                <div class="created-by"
                                    title="{{equipmentDetails.flagCreatedDateTime| date : 'M/dd/yy, h:mm a'}}">
                                    {{flaggedTime}}
                                </div>
                                <div class="created-by" (mouseout)="out()"
                                    (mouseover)="fillToolTipObj($event,equipmentDetails.flagCreatedBy)">
                                    <div class="custom-tooltip flag-tooltip">
                                        &nbsp; {{equipmentDetails.flagCreatedBy.name}} {{equipmentDetails.flagCreatedBy.isActive === 0 ? ' (inactive)' : ''}}
                                        <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="device-accordion">
                    <div class="filter-tabs"
                        [ngClass]="{'project-filter-tabs': (fromProject && (equipmentDetails && (equipmentDetails.type !== 'device' || !equipmentDetails.isHub))),'site-filter-tabs':!fromProject ,'project-archive-filter-tabs':fromProject && (equipmentDetails && equipmentDetails.type !== 'device') && (equipmentDetails && (equipmentDetails.isArchived || buildingDetails.isProjectArchived)) && equipmentDetails && equipmentDetails.projectId !== null, 'project-site-device-tabs': fromProject && equipmentDetails && equipmentDetails.projectId === null && !equipmentDetails.isHub}">
                        <accordion [closeOthers]="false" id="equipment-device-details">
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(0)"
                                (isOpenChange)="onAccordionChange(0, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Identification">
                                        Identification
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group ">
                                                <label>Device Name&nbsp;<sup>*</sup>
                                                    <em *ngIf="equipmentDetails.parentId===null && countDuplicateName>1 && !equipmentDetails.isArchived && !(fromProject && !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                                null)" class="fas fa-warning yellow-text cursor-pointer"
                                                        title="There {{countDuplicateName === 2 ? 'is' : 'are'}} {{countDuplicateName-1}} other {{countDuplicateName === 2 ? (fromProject ? 'Device/Task' : 'Device') : (fromProject ? 'Devices/Tasks' : 'Devices')}} with the same name on this Plan"></em>
                                                </label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Device Name'"
                                                    (input)="onInput($event)" (blur)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['name']"></so-textbox>
                                                <so-control-messages [name]="'Name'"
                                                    [control]="equipmentDetailsForm.controls.name">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Abbreviated Name&nbsp;</label>
                                                <em *ngIf="equipmentDetails.parentId===null && countDuplicateAbbreviatedName > 1 && !equipmentDetails.isArchived && !(fromProject && !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                            null)" class="fas fa-warning yellow-text cursor-pointer"
                                                    title="There {{countDuplicateAbbreviatedName === 2 ? 'is' : 'are'}} {{countDuplicateAbbreviatedName-1}} other {{countDuplicateAbbreviatedName === 2 ? (fromProject ? 'Device/Task' : 'Device') : (fromProject ? 'Devices/Tasks' : 'Devices')}} with the same Abbreviated Name on this Plan"></em>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Abbreviated Name'"
                                                    (input)="onInput($event)" (blur)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['abbreviatedName']"></so-textbox>

                                                <so-control-messages [name]="'Abbreviated Name'"
                                                    [control]="equipmentDetailsForm.controls.abbreviatedName">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>System Type</label>
                                                <so-textbox [placeHolder]="'System Type'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['categoryName']"></so-textbox>
                                            </div>
                                            <div class="form-group">
                                                <label>Device Type </label>
                                                <so-textbox [placeHolder]="'Device Type'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['typeName']"></so-textbox>
                                            </div>

                                            <div class="form-group device-color-form-group"
                                                [ngClass]="{'not-allowed' : isFormDisabled}"
                                                *ngIf="pinColorType === 'Device Color' && isEdit">
                                                <label>Device Color </label>
                                                <div>

                                                    <div class="d-flex mb-1">
                                                        <label class="device-color-label">System Type</label>
                                                        <div class="pin-color me-2"
                                                            (click)="!isFormDisabled ? (equipmentDetails.equipment.pinColor) : ''"
                                                            [ngClass]="{'active': (equipmentDetails.equipment.pinColor ===
                              equipmentDetailsForm.get('pinColor').value)? true : false, 'not-allowed': isFormDisabled}"
                                                            [ngStyle]="{'background-color': equipmentDetails.equipment.pinColor}">
                                                        </div>
                                                        <label>or</label>
                                                    </div>
                                                    <div class="d-flex">
                                                        <label class="device-color-label">Custom Color</label>
                                                        <ul class="custom-color-panel">
                                                            <li *ngFor="let color of codeForDeviceColor"
                                                                (click)="changePinColor(color.colorCode)">
                                                                <div class="pin-color"
                                                                    [ngClass]="{'not-allowed': isFormDisabled,'active': color.colorCode === equipmentDetailsForm.controls.pinColor.value}"
                                                                    [ngStyle]="{'background-color': color.colorCode}">
                                                                    <input type="hidden" class="form-control"
                                                                        formControlName="pinColor">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Manufacturer</label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Manufacturer'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['manufacturer']"></so-textbox>
                                                <so-control-messages [name]="'Manufacturer'"
                                                    [control]="equipmentDetailsForm.controls.manufacturer">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Part Number</label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Part Number'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['partNumber']"></so-textbox>
                                                <so-control-messages [name]="'Part Number'"
                                                    [control]="equipmentDetailsForm.controls.partNumber">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group" *ngIf="isEdit && !(fromProject &&
                                    !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                    null); else disablededDesc">
                                                <label>Description</label>
                                                <so-textarea [disabled]="(fromProject &&
                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                            null) ? true : false" [maxLength]="4000" [placeHolder]="'Description'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['description']"></so-textarea>
                                                <so-control-messages [name]="'Description'"
                                                    [control]="equipmentDetailsForm.controls.description">
                                                </so-control-messages>
                                            </div>
                                            <ng-template #disablededDesc>
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <p class="form-control comment-desc linkified-textarea">
                                                        <span
                                                            *ngIf="equipmentDetailsForm.controls.description.value === null"
                                                            class="placeholder">Description</span>
                                                        <span
                                                            [innerHTML]="equipmentDetailsForm.controls.description.value | linkify:optionsE">

                                                        </span>
                                                    </p>
                                                </div>
                                            </ng-template>
                                            <div class="form-group"
                                                *ngIf="(!fromProject && equipmentDetails.projectId === null) || (fromProject && !equipmentDetails?.isPublishedToAMT && !equipmentDetails?.isArchived && equipmentDetails?.projectId === null)">
                                                <label class="d-flex">Operational Status</label>
                                                <div>
                                                    <div class="btn-group btn-status-group">
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active':equipmentDetailsForm.controls['status'].value ===
                                              'NotWorking'}" class="btn NotWorking"
                                                            (click)="setStatusData('NotWorking')">
                                                            <span class="inner-btn">
                                                                <em>{{notWorkingText}}</em>
                                                            </span>
                                                        </label>

                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['status'].value === 'OperationalWithIssue'}"
                                                            class="btn OperationalWithIssue"
                                                            (click)="setStatusData('OperationalWithIssue')">
                                                            <span class="inner-btn">
                                                                <em>{{operationalWithIssueText}}</em>
                                                            </span>
                                                        </label>
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['status'].value === 'Operational'}"
                                                            class="btn Operational"
                                                            (click)="setStatusData('Operational')">
                                                            <span class="inner-btn">
                                                                <em>{{operational}}</em>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="!fromProject && equipmentDetails.referenceFloorEquipmentId">
                                                <label>Project</label>
                                                <div class="project-name">
                                                    <a href="javascript:void(0)"
                                                        (click)="goToRefrenceDevice()">{{equipmentDetails.referenceProjectName}}</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(1)"
                                (isOpenChange)="onAccordionChange(1, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Installation">
                                        Installation
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group device-group install-hours"
                                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                                <label>Budgeted Hours</label>
                                                <so-textbox [maxLength]="5" [placeHolder]="'3.5'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [customDirective]="true"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['installHours']"></so-textbox>
                                                <so-control-messages [name]="'Budgeted Hours'"
                                                    [control]="equipmentDetailsForm.controls.installHours">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group price-group replace-cost-group"
                                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                                <label>Budgeted Cost</label>
                                                <div>
                                                    <span>$</span>
                                                    <so-textbox [maxLength]="15" [placeHolder]="'0.00'"
                                                        (blur)="saveDetails(false)" (input)="onInput($event)"
                                                        [customDirective]="true"
                                                        [inputControlName]="$any(equipmentDetailsForm).controls['budgetedCost']"></so-textbox>
                                                    <so-control-messages [name]="'Budgeted Cost'"
                                                        [control]="equipmentDetailsForm.controls.budgetedCost">
                                                    </so-control-messages>
                                                </div>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                                <label>Assigned To</label>
                                                <select class="form-control" [attr.disabled]="(fromProject &&
                                        !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                        null) ? '' : null" formControlName="assignedToUser"
                                                    (change)="saveDetails(false)">
                                                    <option *ngFor="let item of installedByUsers"
                                                        [ngStyle]="{'display': item.isRemoved? 'none': 'block'}"
                                                        [ngValue]="item.key">
                                                        {{item.value}} {{item && item.isRemoved ? '(Inactive)':''}}
                                                    </option>
                                                </select>
                                                <so-control-messages [name]="'Assigned To'"
                                                    [control]="equipmentDetailsForm.controls.assignedToUser">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Date Due</label>
                                                <so-datepicker
                                                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY',showWeek: false }"
                                                    (datePickerChange)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['dueDate']"
                                                    [disabled]="!isEdit || isFormDisabled || (fromProject &&
                                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                            null) ? true : false" [id]="'dueDate'"></so-datepicker>
                                            </div>
                                            <div class="form-group">
                                                <label>Installed By</label>
                                                <select class="form-control" [attr.disabled]="(fromProject && !equipmentDetails.isPublishedToAMT &&
                            !equipmentDetails.isArchived && equipmentDetails.projectId === null) ? '' : null"
                                                    formControlName="installedByUser" (change)="saveDetails(false)">
                                                    <option *ngFor="let item of installedByUsers"
                                                        [ngStyle]="{'display': item.isRemoved? 'none': 'block'}"
                                                        [ngValue]="item.key">
                                                        {{item.value}} {{item && item.isRemoved ? '(Inactive)':''}}
                                                    </option>
                                                </select>
                                                <so-control-messages [name]="'Installed By'"
                                                    [control]="equipmentDetailsForm.controls.installedByUser">
                                                </so-control-messages>
                                            </div>

                                            <div class="form-group">
                                                <label>Date Installed</label>
                                                <so-datepicker [disabled]="!isEdit || isFormDisabled || (fromProject &&
                                        !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                        null) ? true : false" [id]="'dateInstalled'"
                                                    (datePickerChange)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['installationDate']"></so-datepicker>
                                            </div>


                                            <div class="form-group"
                                                *ngIf="isEdit && fromProject && equipmentDetails.projectId !== null; else disablededInstructionsDesc">
                                                <label>Instructions</label>
                                                <so-textarea [disabled]="(fromProject &&
                                                !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                                null) ? true : false" [maxLength]="4000" [placeHolder]="'Instructions'"
                                                    (input)="onInput($event)" (blur)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['instructions']"></so-textarea>
                                                <so-control-messages [name]="'instructions'"
                                                    [control]="equipmentDetailsForm.controls.instructions">
                                                </so-control-messages>
                                            </div>
                                            <ng-template #disablededInstructionsDesc>
                                                <div class="form-group"
                                                    *ngIf="fromProject && equipmentDetails.projectId !== null">
                                                    <label>Instructions</label>
                                                    <p class="form-control comment-desc linkified-textarea">
                                                        <span
                                                            *ngIf="equipmentDetailsForm.controls.instructions.value === null || equipmentDetailsForm.controls.instructions.value === ''"
                                                            class="placeholder">Instructions</span>
                                                        <span
                                                            [innerHTML]="equipmentDetailsForm.controls.instructions.value | linkify:optionsE">

                                                        </span>
                                                    </p>
                                                </div>
                                            </ng-template>

                                            <div class="form-group" *ngIf="isEdit && !(fromProject &&
                        !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                        null); else disablededInstallDesc">
                                                <label>Field Notes</label>
                                                <so-textarea [disabled]="(fromProject &&
                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                            null) ? true : false" [maxLength]="4000" [placeHolder]="'Field Notes'"
                                                    (input)="onInput($event)" (blur)="saveDetails(false)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['installationNotes']"></so-textarea>
                                                <so-control-messages [name]="'installationNotes'"
                                                    [control]="equipmentDetailsForm.controls.installationNotes">
                                                </so-control-messages>
                                            </div>
                                            <ng-template #disablededInstallDesc>
                                                <div class="form-group">
                                                    <label>Field Notes</label>
                                                    <p class="form-control comment-desc linkified-textarea">
                                                        <span
                                                            *ngIf="equipmentDetailsForm.controls.installationNotes.value === null"
                                                            class="placeholder">Field Notes</span>
                                                        <span
                                                            [innerHTML]="equipmentDetailsForm.controls.installationNotes.value | linkify:optionsE">

                                                        </span>
                                                    </p>
                                                </div>
                                            </ng-template>

                                            <div class="form-group"
                                                *ngIf="fromProject && equipmentDetails.projectId !== null">
                                                <label>Install Status</label>
                                                <div>
                                                    <div class="btn-group btn-status-group">
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['installStatus'].value === 'Planned'}"
                                                            class="btn NotWorking"
                                                            (click)="setInstallStatus('Planned')">
                                                            <span class="inner-btn">
                                                                <em>Planned</em>
                                                            </span>
                                                        </label>
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['installStatus'].value === 'InProgress'}"
                                                            class="btn OperationalWithIssue"
                                                            (click)="setInstallStatus('InProgress')">
                                                            <span class="inner-btn">
                                                                <em>In Progress</em>
                                                            </span>
                                                        </label>
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['installStatus'].value === 'Installed'}"
                                                            class="btn Operational"
                                                            (click)="setInstallStatus('Installed')">
                                                            <span class="inner-btn">
                                                                <em>Installed</em>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="(fromProject && equipmentDetails.projectId !== null)">
                                                <label class="d-flex">Operational Status</label>
                                                <div>
                                                    <div class="btn-group btn-status-group">
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active':equipmentDetailsForm.controls['status'].value ===
                                    'NotWorking'}" class="btn NotWorking" (click)="setStatusData('NotWorking')">
                                                            <span class="inner-btn">
                                                                <em>{{notWorkingText}}</em>
                                                            </span>
                                                        </label>

                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['status'].value === 'OperationalWithIssue'}"
                                                            class="btn OperationalWithIssue"
                                                            (click)="setStatusData('OperationalWithIssue')">
                                                            <span class="inner-btn">
                                                                <em>{{operationalWithIssueText}}</em>
                                                            </span>
                                                        </label>
                                                        <label
                                                            [ngStyle]="{'cursor':isFormDisabled ? 'not-allowed' : 'pointer'}"
                                                            [ngClass]="{'active': equipmentDetailsForm.controls['status'].value === 'Operational'}"
                                                            class="btn Operational"
                                                            (click)="setStatusData('Operational')">
                                                            <span class="inner-btn">
                                                                <em>{{operational}}</em>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(2)"
                                (isOpenChange)="onAccordionChange(2, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Images">
                                        Images {{hasImage ? '('+equipmentDetails.images.length +')': ''}}
                                    </div>
                                </div>
                                <div style="height:100%; width:100%;min-height: 100px;"
                                    [ngStyle]="{'min-height': isEdit && fileError && !hideFileValidationMessage ? '100px': ''}">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix"
                                            [ngStyle]="{'min-height':isCompressing ? '200px' : ''}">
                                            <so-loader *ngIf="isCompressing" [tableloader]="true"></so-loader>
                                            <div class="row dragged-invalid-file">
                                                <div class="col-sm-10">
                                                    <div *ngIf="isEdit && !isFormDisabled"
                                                        class=" file-uploaded clearfix">
                                                        <so-file-upload (click)="$event.stopPropagation()"
                                                            [uploadedFiles]="uploadedFile" [isShowFileIcon]="true"
                                                            (uploadedFilesChange)="fileUploaded($event)"
                                                            [fileUploadOptions]="fileUploadOptions"
                                                            (invalidFileDragged)="fileUploadFail()"
                                                            (errorMessageChange)="fileError = true"
                                                            [hideFileValidationMessage]="hideFileValidationMessage">
                                                        </so-file-upload>
                                                    </div>
                                                </div>

                                                <div class="col-sm-2">
                                                    <span *ngIf="isEdit && !isFormDisabled"
                                                        class="text-center btn-camera blue-text" title="Take Picture"
                                                        (click)="$event.stopPropagation();openCameraPopup()">
                                                        <em class="fa fa-camera"></em>
                                                    </span>
                                                </div>
                                                <div>
                                                    <div *ngIf="invalidFileDragged"
                                                        class="help-block text-danger browse-invalid">
                                                        {{limitErrorMessage}}</div>
                                                </div>
                                            </div>
                                            <!-- Displaying Image Section Start -->
                                            <div class="equipment-thumb-block" *ngIf="hasImage; else noImage">
                                                <div *ngFor="let equipImg of uploadedFiles | imageGroupBy: 'stage' ; trackBy: detailsTrackByStage"
                                                    class="d-inline-block w-100 "
                                                    [ngClass]="{'images-section' : equipImg.showImageSection}">
                                                    <div *ngIf="!equipImg.isDeleted">
                                                        <label *ngIf="equipImg.showImageSection">
                                                            {{equipImg.key === '' ? 'Images without Stage' :
                                                            equipImg.key + '' +'
                                                            Images'}}
                                                        </label>
                                                        <ng-container
                                                            *ngFor="let equipImgDate of equipImg.value | imageGroupBy: 'createdDateTime'; trackBy: detailsTrackByDateTime">
                                                            <div *ngIf="equipImgDate.showDateSection"
                                                                class="d-inline-block w-100">
                                                                <div class="d-inline-block images-upload-date mb-1">
                                                                    {{equipImgDate.key | date : 'MM/dd/yyyy'}}
                                                                </div>
                                                                <div>
                                                                    <ng-container
                                                                        *ngFor="let imgFile of equipImgDate.value; let index = index;trackBy: detailsTrackBy">
                                                                        <div class="equipment-thumb-inner"
                                                                            *ngIf="!imgFile.isDeleted">
                                                                            <div>
                                                                                <div class="div-table">
                                                                                    <span
                                                                                        *ngIf="isEdit && !isFormDisabled"
                                                                                        class="fa fa-times img-thumb-remove"
                                                                                        (click)="$event.stopPropagation();openImageDeletePopup(imgFile,index)">
                                                                                    </span>
                                                                                    <div class="div-table-cell">
                                                                                        <div
                                                                                            class="equipment-thumb-img">
                                                                                            <div
                                                                                                *ngIf="imgFile.isLoading">
                                                                                                <so-loader
                                                                                                    [smallloader]='true'></so-loader>

                                                                                            </div>
                                                                                            <img alt=""
                                                                                                *ngIf="imgFile.thumbnailAwsImageURL"
                                                                                                [appRetryImage]="imgFile.thumbnailAwsImageURL"
                                                                                                [src]="imgFile.thumbnailAwsImageURL"
                                                                                                class=" fallback img-responsive"
                                                                                                (click)="$event.stopPropagation();openImagePopup(imgFile, index)"
                                                                                                soImgOrientation
                                                                                                [isImageLoaded]="imgFile.isLoading"
                                                                                                [isFloorPlanDevices]="true"
                                                                                                (loaderOff)="toggleImageLoader(imgFile, false)"
                                                                                                (load)="toggleImageLoader(imgFile, false)"
                                                                                                (error)="toggleImageLoader(imgFile, false)"
                                                                                                [parent]="'equipment-thumb-img'"
                                                                                                [index]="index"
                                                                                                [apply]="imgFile">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="equipment-caption"
                                                                                    (click)="$event.stopPropagation();openImagePopup(imgFile, index)"
                                                                                    *ngIf="imgFile.fileName">
                                                                                    <a style="cursor:pointer"
                                                                                        title="{{imgFile.fileName}}">{{imgFile.fileName}}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #noImage>
                                                <div>
                                                    <!-- No images available -->
                                                    Drop image, take picture or browse
                                                </div>
                                            </ng-template>
                                            <!-- Displaying Image Section End -->

                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(3)"
                                (isOpenChange)="onAccordionChange(3, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Technical Details">
                                        Technical
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group"
                                                *ngIf="equipmentDetails && equipmentDetails.equipment && equipmentDetails.equipment.isSmart">
                                                <label>IP / Analog</label>
                                                <select class="form-control" formControlName="dataType"
                                                    (change)="saveDetails(false)">
                                                    <option value="null">None</option>
                                                    <option *ngFor="let item of equipmentIPTypeList" [ngValue]="item">
                                                        {{item}}</option>
                                                </select>
                                                <so-control-messages [name]="'IP / Analog'"
                                                    [control]="equipmentDetailsForm.controls.dataType">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Serial Number </label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Serial Number'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['serialNumber']"></so-textbox>
                                                <so-control-messages [name]="'Serial Number'"
                                                    [control]="equipmentDetailsForm.controls.serialNumber">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group" *ngIf="equipmentDetails.equipment.isSmart">
                                                <label>IP Address</label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'IP Address'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['ipAddress']"></so-textbox>
                                                <div *ngIf="equipmentDetailsForm.controls.ipAddress.invalid"
                                                    class="help-block text-danger">
                                                    Invalid IP Address</div>

                                            </div>
                                            <div class="form-group" *ngIf="equipmentDetails.equipment.isSmart">
                                                <label>MAC Address</label>
                                                <so-textbox maxlength="17" [placeHolder]="'MAC Address'"
                                                    (blur)="formatMACAddress($event)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['macAddress']"></so-textbox>
                                                <div *ngIf="equipmentDetailsForm.controls.macAddress.invalid"
                                                    class="help-block text-danger">Invalid MAC Address</div>
                                            </div>
                                            <div class="form-group">
                                                <label>Barcode</label>
                                                <so-textbox [maxLength]="80" [placeHolder]="'Barcode'"
                                                    (blur)="saveDetails(false)" (input)="onInput($event)"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['barcode']"></so-textbox>
                                                <so-control-messages [name]="'Barcode'"
                                                    [control]="equipmentDetailsForm.controls.barcode">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Interior / Exterior</label>
                                                <select class="form-control" formControlName="deviceLocation"
                                                    (change)="saveDetails(false)">
                                                    <option value="null">Not Set</option>
                                                    <option *ngFor="let item of equipmentLocationList" [ngValue]="item">
                                                        {{item}}</option>
                                                </select>
                                                <so-control-messages [name]="'Device Location'"
                                                    [control]="equipmentDetailsForm.controls.deviceLocation">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group device-group">
                                                <label>Height ({{unitOfMeasurements==='Meters'? 'm':'ft'}})</label>
                                                <so-textbox [disabled]="equipmentDetails.isArchived || (fromProject &&
                                !equipmentDetails.isPublishedToAMT  && equipmentDetails.projectId ===
                                null) ? true : false" class="form-control" (blur)="saveDetails(false)"
                                                    [customDirective]="true"
                                                    [placeHolder]="'Height ('+ (unitOfMeasurements==='Meters'? 'm':'ft') +')'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['height']"
                                                    [min]="0" [maxLength]="15" (input)="onInput($event)"></so-textbox>
                                                <so-control-messages
                                                    *ngIf="equipmentDetailsForm.controls['height'].value!==null && equipmentDetailsForm.controls['height'].value!==undefined"
                                                    [name]="'Height'" [control]="equipmentDetailsForm.controls.height">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Custom Device ID</label>
                                                <so-textbox [disabled]="equipmentDetails.isArchived || (fromProject &&
                                            !equipmentDetails.isPublishedToAMT  && equipmentDetails.projectId ===
                                            null) ? true : false" (blur)="saveDetails(false)"
                                                    [placeHolder]="'Custom Device ID'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['customDeviceId']"
                                                    [maxLength]="60" (input)="onInput($event)"></so-textbox>
                                                <so-control-messages [name]="'Custom Device ID'"
                                                    [control]="$any(equipmentDetailsForm).controls['customDeviceId']">
                                                </so-control-messages>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(4)"
                                (isOpenChange)="onAccordionChange(4, $event)"
                                *ngIf="equipmentDetails.equipment.isCoverage">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Coverage">
                                        Coverage
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group">
                                                <div class="position-relative d-flex justify-content-between">
                                                    <label>Coverage Area</label>
                                                    <div class="form-check form-switch" *ngIf="isEdit">
                                                        <input class="form-check-input" type="checkbox" role="switch"
                                                            [disabled]="equipmentDetails.isArchived || isFormDisabled || (fromProject &&
                                                !equipmentDetails.isPublishedToAMT  && equipmentDetails.projectId ===
                                                null) ? true : false" id="flexSwitchCheckChecked"
                                                            [checked]="equipmentDetails.coverageArea"
                                                            (change)="onSwitchChangeEvent($event)">
                                                    </div>
                                                </div>
                                                <span class="placeholder" *ngIf="!equipmentDetails.coverageArea">Enables
                                                    Coverage
                                                    Direction, Angle & Range </span>

                                                <div class="mt-2" *ngIf="equipmentDetails.coverageArea">
                                                    <label>Coverage Direction</label>
                                                    <div class="pin-slider-wrapper mb-1">
                                                        <div class="pin-slider-card">
                                                            <ngx-slider (click)="$event.stopPropagation()"
                                                                formControlName="coverageDirection" #ngxSliderDirection
                                                                (userChange)="directionChange($event,true)"
                                                                [options]="directionOptions"></ngx-slider>
                                                            <span class="coverage-value">
                                                                <input type="text" soNumbersOnly class="form-control"
                                                                    maxlength="3" min="0" max="360"
                                                                    formControlName="coverageDirection"
                                                                    (input)="directionChange($event,false);"
                                                                    (blur)="directionChange($event,false,true);">
                                                                <sup class="degree">&deg;</sup>
                                                                <so-control-messages [name]="'coverageDirection'"
                                                                    [control]="equipmentDetailsForm.controls.coverageDirection">
                                                                </so-control-messages>
                                                                <div *ngIf="(directionValue < 0 || directionValue > 360)"
                                                                    class="left help-block text-danger left-tool-tip">
                                                                    Coverage Direction
                                                                    should
                                                                    be
                                                                    greater than 0 and less than 361
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <label>Coverage Angle</label>
                                                    <div class="pin-slider-wrapper mb-2">
                                                        <div class="pin-slider-card">
                                                            <ngx-slider formControlName="coverageAngle" #ngxSliderAngle
                                                                (userChange)="angleChange($event,true)"
                                                                [options]="angleOptions"></ngx-slider>
                                                            <span class="coverage-value">
                                                                <input type="text" soNumbersOnly class="form-control"
                                                                    maxlength="3" min="0" max="360"
                                                                    formControlName="coverageAngle"
                                                                    (input)="angleChange($event,false);"
                                                                    (blur)="angleChange($event,false,true);">
                                                                <sup class="degree">&deg;</sup>
                                                                <so-control-messages [name]="'coverageAngle'"
                                                                    [control]="equipmentDetailsForm.controls.coverageAngle">
                                                                </so-control-messages>
                                                                <div *ngIf="(angleValue < 0 || angleValue > 360)"
                                                                    class="left help-block text-danger left-tool-tip">
                                                                    Coverage Angle should
                                                                    be
                                                                    greater than 0 and less than 361
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <label>Coverage Range</label>
                                                    <div class="pin-slider-wrapper mb-1">
                                                        <div class="pin-slider-card">
                                                            <ngx-slider formControlName="coverageRange" #ngxSliderRange
                                                                (userChange)="rangeChange($event,true)"
                                                                [options]="rangeOptions"></ngx-slider>
                                                            <span class="coverage-value">
                                                                <input type="text" soNumbersOnly class="form-control"
                                                                    maxlength="3" min="0" max="100"
                                                                    [disabled]="isFormDisabled"
                                                                    value="{{ equipmentDetailsForm.controls.coverageRange.value | number : '1.0-0' }}"
                                                                    (input)="rangeChange($event,false);"
                                                                    (blur)="rangeChange($event,false,true);">
                                                                <so-control-messages [name]="'coverageRange'"
                                                                    [control]="equipmentDetailsForm.controls.coverageRange">
                                                                </so-control-messages>
                                                                <div *ngIf="(angleValue < 0 || angleValue > 360)"
                                                                    class="left help-block text-danger left-tool-tip">
                                                                    Coverage Range should
                                                                    be
                                                                    greater than 0 and less than 101
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(5)"
                                (isOpenChange)="onAccordionChange(5, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle" title="Connections">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                Connections
                                                <em *ngIf="isCableEnable" (mouseover)="connectionToolTipObj($event)"
                                                    class="far fa-info-circle icon-information custom-tooltip role-tooltip-icon cursor-pointer">
                                                    <so-tool-tip style="text-align: left;" [customMessage]="false"
                                                        [customConnectionToolTip]="true"
                                                        [toolTipObj]="toolTipConnectionObj">
                                                    </so-tool-tip>
                                                </em>
                                            </div>
                                            <div>

                                                <em class="far fa-network-wired icon-connection"></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group">
                                                <label>IDF / MDF</label>
                                                <so-textbox [disabled]="equipmentDetails.isArchived || (fromProject &&
                                            !equipmentDetails.isPublishedToAMT  && equipmentDetails.projectId ===
                                            null) ? true : false" (blur)="saveDetails(false)"
                                                    [placeHolder]="'IDF / MDF'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['deviceConnectedTo']"
                                                    [maxLength]="80" (input)="onInput($event)"></so-textbox>
                                                <so-control-messages [name]="'IDF / MDF'"
                                                    [control]="$any(equipmentDetailsForm).controls['deviceConnectedTo']">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Hub</label>
                                                <br />
                                                <label
                                                    *ngIf="netWorkSwitches && netWorkSwitches.length === 0 && !(fromProject && equipmentDetails.projectId === null)">
                                                    Right-click on any Device in
                                                    <strong>{{buildingDetails.name}}</strong>
                                                    and select
                                                    the “Make Hub”
                                                    option
                                                    to make it
                                                    available as a Hub here.
                                                </label>
                                                <ng-container
                                                    *ngIf="fromProject && equipmentDetails.projectId === null">
                                                    <div *ngIf="equipmentDetails.networkSwitchLinkName">
                                                        <input [attr.disabled]="true" type="text" class="form-control"
                                                            formControlName="networkSwitchLinkName">
                                                    </div>
                                                    <label *ngIf="!equipmentDetails.networkSwitchLinkName">
                                                        No Hub Selected
                                                    </label>
                                                </ng-container>

                                                <select class="form-control" formControlName="networkSwitchLinkId"
                                                    [attr.disabled]="equipmentDetails.isArchived || (fromProject &&
                          !equipmentDetails.isPublishedToAMT  && equipmentDetails.projectId ===
                          null) ? '' : null" *ngIf="netWorkSwitches && netWorkSwitches.length > 0 && !(fromProject && equipmentDetails.projectId ===
                          null)" (change)="saveDetails(false)">
                                                    <option value="null">No Hub Selected</option>
                                                    <option *ngFor="let item of netWorkSwitches;let i=index"
                                                        [hidden]="!(item.id !== equipmentDetails.id)"
                                                        [ngValue]="item.id">
                                                        {{item.name}} ({{item.floorName}})</option>
                                                </select>
                                                <so-control-messages [name]="'Network Switch'"
                                                    [control]="equipmentDetailsForm.controls.networkSwitchLinkId">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group">
                                                <label>Port</label>
                                                <so-textbox [disabled]="equipmentDetails.isArchived || (fromProject &&
                                            !equipmentDetails.isPublishedToAMT   && equipmentDetails.projectId ===
                                            null) ? true : false" (blur)="saveDetails(false)" [placeHolder]="'Port'"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['port']"
                                                    [maxLength]="20" (input)="onInput($event)"></so-textbox>
                                                <so-control-messages [name]="'Port'"
                                                    [control]="equipmentDetailsForm.controls.port">
                                                </so-control-messages>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="$any(equipmentDetailsForm).controls['networkSwitchLinkId'].value > 0">
                                                <label
                                                    *ngIf="equipmentDetails.isConnectionLineOrCablePath && $any(equipmentDetailsForm).controls['networkSwitchLinkId'].value > 0 && $any(equipmentDetailsForm).controls['connectionLength'].value > 0">Connection
                                                    Length ({{unitOfMeasurements==='Meters'?'meters':'feet'}})</label>
                                                <input
                                                    *ngIf="equipmentDetails.isConnectionLineOrCablePath && $any(equipmentDetailsForm).controls['networkSwitchLinkId'].value > 0 && $any(equipmentDetailsForm).controls['connectionLength'].value > 0"
                                                    type="textbox"
                                                    [value]="$any(equipmentDetailsForm).controls['connectionLength'].value | number: '1.1-1'"
                                                    class="form-control" [attr.disabled]="true">

                                                <div
                                                    *ngIf="((isCustomerAdmin || isGlobalAdmin || isCustomerManager || isProjectManager) && !(!equipmentDetails.isConnectionLineOrCablePath && $any(equipmentDetailsForm).controls['networkSwitchLinkId'].value > 0) && ((floorScale===0 || floorScale===null || floorScale===undefined || floorScale==='NaN')))">
                                                    <label>Connection Length
                                                        ({{unitOfMeasurements==='Meters'?'meters':'feet'}})</label>
                                                    <p>
                                                        Add a Plan Scale in the Plan Library for automatically
                                                        calculated
                                                        Connection
                                                        Lengths.
                                                    </p>
                                                </div>
                                                <div
                                                    *ngIf="!equipmentDetails.isConnectionLineOrCablePath && $any(equipmentDetailsForm).controls['networkSwitchLinkId'].value > 0">
                                                    <label>Connection Length
                                                        ({{unitOfMeasurements==='Meters'?'meters':'feet'}})</label>
                                                    <p
                                                        *ngIf="floorScale===0 || floorScale===null || floorScale===undefined || floorScale==='NaN'">
                                                        Add a Plan Scale in the Plan Library for automatically
                                                        calculated
                                                        Connection
                                                        Lengths.
                                                    </p>
                                                    <p>
                                                        Not supported for Hubs on other Plans.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="d-flex"
                                                *ngIf="isEdit && isCableEnable && !equipmentDetails.isLiveDevice && !equipmentDetails.isArchived && equipmentDetails.cableCoordinates!==null && equipmentDetails.cableCoordinates!==undefined && equipmentDetails.networkSwitchLinkId!==undefined && equipmentDetails.networkSwitchLinkId!==null && equipmentDetails.networkSwitchLinkId >0">
                                                <a href="javascript:void(0)" (click)="resetPathway()"
                                                    class="btn btn-primary mx-auto my-1"> Reset Pathway
                                                </a>
                                            </div>
                                            <div style="height:100%; width:100%">
                                                <div role="tabpanel" aria-labelledby="headingOne">
                                                    <div class="equipment-details clearfix">
                                                        <div *ngIf="equipmentDetails.isHub">
                                                            <h3 class="">Connected Devices</h3>
                                                            <p
                                                                *ngIf="equipmentDetails.networkSwitchLinkEquipments && equipmentDetails.networkSwitchLinkEquipments.length === 0">
                                                                On another Device, select this Device as a Hub to
                                                                connect it.
                                                            </p>

                                                            <div class=""
                                                                *ngIf="equipmentDetails.networkSwitchLinkEquipments && equipmentDetails.networkSwitchLinkEquipments.length > 0">
                                                                <div class="table-responsive tab-scroll-table-full">
                                                                    <table class="table associated-device-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style="width:250px" class="ps-0">
                                                                                    Device Name</th>
                                                                                <th class="pe-0 text-end">Port</th>
                                                                            </tr>
                                                                            <tr
                                                                                *ngFor="let networkSwitchItem of equipmentDetails.networkSwitchLinkEquipments  | orderBy: 'name'">
                                                                                <td class="ps-0">
                                                                                    <label class="mb-0"
                                                                                        *ngIf="fromProject"
                                                                                        (click)="selectDevice(networkSwitchItem)"
                                                                                        [ngClass]="{ 'NotWorkingDevice' : networkSwitchItem.installStatus === 'Planned','OperationalWithIssueDevice' : networkSwitchItem.installStatus === 'InProgress','OperationalDevice' : networkSwitchItem.installStatus === 'Installed'}">{{networkSwitchItem.name}}</label>
                                                                                    <label class="mb-0"
                                                                                        *ngIf="!fromProject"
                                                                                        (click)="selectDevice(networkSwitchItem)"
                                                                                        [ngClass]="{ 'OperationalDevice' : networkSwitchItem.status === 'Operational','NotWorkingDevice' : networkSwitchItem.status === 'NotWorking','OperationalWithIssueDevice' : networkSwitchItem.status === 'OperationalWithIssue'}">{{networkSwitchItem.name}}</label>
                                                                                    <span
                                                                                        class="d-block floor-text">{{networkSwitchItem.floorName}}</span>
                                                                                </td>
                                                                                <td class="pe-0 text-end port-text">
                                                                                    {{((networkSwitchItem.port !== null
                                                                                    &&
                                                                                    networkSwitchItem.port !==
                                                                                    undefined && networkSwitchItem.port
                                                                                    !== '') ?
                                                                                    networkSwitchItem.port
                                                                                    : 'None')}}
                                                                                </td>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(6)"
                                (isOpenChange)="onAccordionChange(6, $event)">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Warranty">
                                        Warranty
                                    </div>
                                </div>
                                <div style="height:100%; width:100%">
                                    <div role="tabpanel" aria-labelledby="headingOne">
                                        <div class="equipment-details clearfix">
                                            <div class="form-group">
                                                <label>Labor Warranty Expiration</label>
                                                <so-datepicker [disabled]="isFormDisabled || (fromProject &&
                                            !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                            null) ? true : false" [id]="'laborWarrantyExpDate'"
                                                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['laborWarrantyExpirationDate']"
                                                    (datePickerChange)="saveDetails(false)"></so-datepicker>

                                            </div>
                                            <div class="form-group">
                                                <label>Device Warranty Expiration</label>
                                                <so-datepicker [id]="'deviceWarrantyExpDate'"
                                                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY' }"
                                                    [inputControlName]="$any(equipmentDetailsForm).controls['deviceWarrantyExpirationDate']"
                                                    (datePickerChange)="saveDetails(false)" [disabled]="isFormDisabled || (fromProject &&
                                                !equipmentDetails.isPublishedToAMT && !equipmentDetails.isArchived && equipmentDetails.projectId ===
                                                null) ? true : false"></so-datepicker>

                                            </div>
                                            <div class="form-group">
                                                <label>Priority</label>
                                                <select class="form-control" formControlName="priority"
                                                    (change)="saveDetails(true)" [attr.disabled]="(fromProject && !equipmentDetails.isPublishedToAMT &&
                          !equipmentDetails.isArchived && equipmentDetails.projectId === null) ? '' : null">
                                                    <option *ngFor="let item of priorityItems" [ngValue]="item.value">
                                                        {{item.key}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group price-group replace-cost-group">
                                                <label>Replacement Cost</label>
                                                <div>
                                                    <span>$</span>
                                                    <so-textbox [maxLength]="15" [placeHolder]="'0.00'"
                                                        (blur)="saveDetails(true)" (input)="onInput($event)"
                                                        [customDirective]="true"
                                                        [inputControlName]="$any(equipmentDetailsForm).controls['price']"></so-textbox>
                                                    <so-control-messages [name]="'Replacement Cost'"
                                                        [control]="equipmentDetailsForm.controls.price">
                                                    </so-control-messages>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>



                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(7)"
                                (isOpenChange)="onAccordionChange(7, $event)"
                                *ngIf="!fromProject && equipmentDetails.type==='device' && isTicketAccessible">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Service Tickets">
                                        Service Tickets
                                        <span class="ticket-count"> ({{associatedTicketCount}})</span>
                                        <!-- <div class="checkbox-label justify-content-end hide-ticket-label"
                                            *ngIf="associatedWithClosedCount > 0">
                                            <so-checkbox
                                                [parentClassName]="!buildingDetails.isSiteArchived ? 'justify-content-end hide-ticket-label' : ''"
                                                [isChecked]="canShowClosedTicket" (change)="toggleTicketStatus($event)"
                                                [disabled]="buildingDetails.isSiteArchived" [id]="'ticketCheck'"
                                                [label]="'Hide Closed Tickets'"></so-checkbox>
                                        </div> -->
                                    </div>
                                </div>
                                <div *ngIf="!fromProject && isTicketAccessible" role="tabpanel"
                                    aria-labelledby="headingOne">
                                    <div class="d-flex align-items-center ">
                                        <!-- <h2 class="mb-0 me-auto">Tickets</h2> -->

                                    </div>
                                    <div class="row table-filter-row" *ngIf="isEditMode">
                                        <div class="col-xl-12">
                                            <div class="table-responsive">
                                                <table class="table tickets-table">
                                                    <thead>
                                                        <tr *ngIf="!(!serviceTickets || serviceTickets.length === 0 || associatedTicketCount === 0 &&
                                                        (associatedClosedTicketCount - associatedTicketCount) === 0)">
                                                            <th [ngClass]="{
                                                                'd-none': associatedTicketCount === 0 && associatedWithClosedCount === 0
                                                              }" style="width: 40%" class="px-0">Subject
                                                            </th>
                                                            <th style="width: 60%" class="px-0 text-end">
                                                                <div class="checkbox-label justify-content-end hide-ticket-label"
                                                                    *ngIf="associatedClosedTicketCount > 0">
                                                                    <so-checkbox
                                                                        [parentClassName]="!buildingDetails.isSiteArchived ? 'justify-content-end hide-ticket-label' : ''"
                                                                        [isChecked]="canShowClosedTicket"
                                                                        (change)="toggleTicketStatus($event)"
                                                                        [disabled]="buildingDetails.isSiteArchived"
                                                                        [id]="'ticketCheck'"
                                                                        [label]="'Hide Closed Tickets'"></so-checkbox>
                                                                </div>
                                                            </th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let item of serviceTickets | ticket:{canShowClosedTicket:canShowClosedTicket,equipmentDetails:equipmentDetails} ;let i = index;trackBy:detailsTrackBy">
                                                            <td class="px-0 py-2" colspan="2"
                                                                [ngClass]="{ 'border-none': serviceTickets.length === 1  || i === serviceTickets.length - 1}">
                                                                <div class="subject">
                                                                    <a style="cursor: pointer;"
                                                                        *ngIf="isGlobalAdmin ||  !(isCustomerTech && loggedInUser && item.assignedTo && loggedInUser.user.id !== item.assignedTo.id);else diffUser"
                                                                        (click)="goToTicket(item.id)"
                                                                        class="green-text">
                                                                        <span class="label "
                                                                            [ngClass]="{'open-status': item.status === statuses.Open,'close-status': item.status === statuses.Closed,'inprogress-status': item.status === statuses.InProgress }">{{item.subject
                                                                            | wrap :45}}</span>
                                                                    </a>
                                                                    <ng-template #diffUser>
                                                                        <span class="label "
                                                                            [ngClass]="{'open-status': item.status === statuses.Open,'close-status': item.status === statuses.Closed,'inprogress-status': item.status === statuses.InProgress }">{{item.subject
                                                                            | wrap :45}}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                        <tr *ngIf="!serviceTickets || serviceTickets.length === 0 || associatedTicketCount === 0 &&
                          (associatedClosedTicketCount - associatedTicketCount) === 0">
                                                            <td class="px-0 py-2 border-none" colspan="3">
                                                                No associated Tickets
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="canShowClosedTicket && associatedTicketCount === 0 && (associatedClosedTicketCount -
                          associatedTicketCount) > 0">
                                                            <td colspan="3" class="px-0 py-2 border-none border-none">
                                                                No associated pending Tickets. Uncheck
                                                                the
                                                                box to view
                                                                <strong>{{ associatedClosedTicketCount -
                                                                    associatedTicketCount}}
                                                                </strong> closed {{
                                                                (associatedClosedTicketCount -
                                                                associatedTicketCount)=== 1 ?
                                                                'ticket':'tickets'}}.

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- goToTicket -->
                                            <div class="ticket-btn-group  text-center"
                                                *ngIf="isTicketAccessible && isEditMode">
                                                <span
                                                    *ngIf="(isTicketSubmitter || permissions.canEdit || permissions.canManage) && !buildingDetails.isSiteArchived && !equipmentDetails.isArchived">
                                                    <a href="javascript:void(0)" (click)="openTicketPopup()"
                                                        class="btn btn-primary add-ticket px-0"><em class="far fa-plus"
                                                            title="Fit to Screen"></em>
                                                        Create New Ticket
                                                    </a>
                                                </span>
                                                <span *ngIf="(isTicketSubmitter || permissions.canEdit || permissions.canManage) && !buildingDetails.isSiteArchived && unlinkedServiceTickets > 0 &&
            !equipmentDetails.isArchived" class="">
                                                    <a href="javascript:void(0)" (click)="linkTicket()"
                                                        class="btn btn-primary  add-ticket me-0"><em class="far fa-plus"
                                                            title="Fit to Screen"></em> Link to
                                                        Ticket
                                                    </a>
                                                </span>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isEditMode">
                                        <div class="col-xl-12">
                                            <p>Save the Device before adding or linking Tickets.</p>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin" [isOpen]="isAccordionOpen(8)"
                                (isOpenChange)="onAccordionChange(8, $event)"
                                *ngIf="!fromProject && equipmentDetails.type==='device' && associatedAuditCount > 0">
                                <div accordion-heading>
                                    <div class="slide-toggle " title="Associated">
                                        Audits
                                        <span class="ticket-count"> ({{associatedAuditCount}})</span>
                                    </div>
                                </div>
                                <div class="mb-3"
                                    *ngIf="!fromProject && equipmentDetails.isParent && equipmentDetails.auditDeviceDetail && equipmentDetails.auditDeviceDetail.length > 0">
                                    <div class="d-flex align-items-center ">
                                        <so-checkbox *ngIf="associatedWithCompletedCount > 0"
                                            [parentClassName]="'justify-content-end hide-ticket-label'"
                                            [isChecked]="hideCompletedAudit" (change)="toggleCompletedAudit($event)"
                                            type="checkbox" [id]="'auditCheck'"
                                            [label]="'Hide Completed Audit'"></so-checkbox>
                                    </div>
                                    <p
                                        *ngIf="!equipmentDetails.auditDeviceDetail || (equipmentDetails.auditDeviceDetail && equipmentDetails.auditDeviceDetail.length === 0) || (associatedWithCompletedCount > 0 && hideCompletedAudit && equipmentDetails.auditDeviceDetail.length === associatedWithCompletedCount)">
                                        No associated Audits
                                    </p>
                                    <div class=""
                                        *ngIf="equipmentDetails.auditDeviceDetail && equipmentDetails.auditDeviceDetail.length > 0">
                                        <div class="table-responsive tab-scroll-table-audit"
                                            [ngStyle]="{'max-height': ((equipmentDetails.networkSwitchLinkEquipments && equipmentDetails.networkSwitchLinkEquipments.length > 0) && serviceTickets.length > 0) ? 'calc(100vh / 3 - 100px)' : 'calc(100vh / 2 - 227px)'}"
                                            [ngClass]="{ 'tab-scroll-table' : (equipmentDetails.networkSwitchLinkEquipments && equipmentDetails.networkSwitchLinkEquipments.length === 0) && serviceTickets.length === 0}">
                                            <table class="table audit-table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:220px" class="px-0">Name</th>
                                                        <th class="pe-0 text-start" style="width:80px">
                                                            Result
                                                        </th>
                                                    </tr>
                                                    <tr
                                                        *ngFor="let item of equipmentDetails.auditDeviceDetail | audit:{hideCompletedAudit: hideCompletedAudit}">
                                                        <td class="ps-0">
                                                            <label class="mb-0 name"><a
                                                                    [ngClass]="{'pass': item.status === 'completed'}"
                                                                    (click)="goToAudit(item)">{{item.auditName}}</a></label>
                                                            <span class="d-block date">{{item.startDate}}
                                                                <ng-container *ngIf="item.status === 'completed'"> to
                                                                    {{item.completedDate}}</ng-container>
                                                            </span>

                                                        </td>
                                                        <td class="pe-0 text-start align-top">{{item.result}}</td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                        </accordion>
                        <div class="d-flex p-2" *ngIf="!equipmentDetails.isNew">
                            <button (click)="createDeviceLink()" aria-label="Shareable Link" class="btn btn-primary"
                                type="button">
                                <em class="fas fa-link icon-shareable-link"></em> Shareable Link
                            </button>
                            <button (click)="deviceHistory()" aria-label="Device History" class="btn btn-primary"
                                type="button">
                                <em class="fas fa-history"></em> Device History
                            </button>

                        </div>
                    </div>
                </div>
                <div *ngIf="!equipmentDetails.isNew" class="px-3 py-2">
                    <div class="date-format form-group filter-date clearfix">
                        <div class="created ">
                            <label>Created:</label>
                            <div class="d-flex">
                                <div class="created-by"
                                    title="{{equipmentDetails.createdDateTime| date : 'M/dd/yy, h:mm a'}}">
                                    {{createdTime}}
                                </div>
                                <div class="created-by ps-1" (mouseout)="out()"
                                    (mouseover)="fillToolTipObj($event,equipmentDetails.createdByUser)">
                                    <div class="custom-tooltip accordian-tooltip">
                                        {{equipmentDetails.createdBy}} {{equipmentDetails.createdByUser.isActive === 0 ? ' (inactive)' : ''}}
                                        <so-tool-tip *ngIf="toolTipObj" [toolTipObj]="toolTipObj"></so-tool-tip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="date-format form-group filter-date  clearfix">
                        <div class="created">

                            <label>Updated:</label>
                            <div class="d-flex">
                                <div class="created-by"
                                    title="{{this.equipmentDetails.modifiedDateTime | date : 'M/dd/yy, h:mm a'}}">
                                    {{updatedTime}}
                                </div>

                                <div class="created-by ps-1" (mouseout)="out()"
                                    (mouseover)="fillToolTipObjUpdate($event,equipmentDetails.updatedByUser)">
                                    <div class="custom-tooltip image-tooltip">
                                        {{equipmentDetails.updatedBy}} {{equipmentDetails.updatedByUser.isActive === 0 ? ' (inactive)' : ''}}
                                        <so-tool-tip [toolTipObj]="toolTipObjUpdate"></so-tool-tip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>