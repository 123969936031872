/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, CodeForDeviceColor, DevicePriority, DeviceTypes, EquipmentIPTypeList, EquipmentLocationList, EquipmentStatusForDevice, EventEmitterType, EventService, FileItem, FileUploadConstant, FileUploadOptions, FileUploadService, FloorDataTransferService, FloorEquipment, Roles, Statuses, Utilities, ValidationService, stateManage } from '@SiteOwl/core';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { EquipmentDetailsService } from '../../service/equipment-details.service';
import b64toBlob from 'b64-to-blob';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ImgDelete } from '../../constant/img-delete.constant';
import { CommonPopupComponent, PixieComponent, ShareableLinkComponent, TakePictureFromCameraComponent, TooltipModel, ViewImageComponent } from '@SiteOwl/UI';
import moment from 'moment/moment';
import { Options, SliderComponent } from 'ngx-slider-v2';
import { CoverageCone } from '../../utils/coverage-cone';
import { EquipmentHistoryComponent } from '../../component/equipment-history/equipment-history.component';
import { Subject, Subscription, debounceTime, distinctUntilChanged, take, takeUntil, timeout } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FloorUtils } from '../../service/floor-utils';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'so-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('highlightAnimation', [
      state('highlighted', style({
        color: '#00B2F8',
      })), transition('* => highlighted', [
        animate('0s ease-in')
      ]),
      transition('highlighted => *', [
        animate('2s ease-out', style({
          color: '#262626',

        }))
      ])
    ])
  ]
})
export class EquipmentDetailsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {


  @Input() equipmentDetails!: FloorEquipment;
  @Input() favoriteEquipmentDetails!: any;
  @Input() isEdit!: any;
  @Input() fromProject!: any;
  @Input() pinColorType!: any;
  @Input() isFavorite!: any;
  @Input() buildingDetails!: any;
  @Input() isDefaultViewOnly = true;
  @Input() deviceStatusData: any;
  @Input() installedByUsers!: any;
  @Input() isCableEnable: any = true;
  @Input() netWorkSwitches: any = [];
  @Input() permissions: any;
  @Input() floorStage: any;
  @Input() hideFileValidationMessage = false
  @Input() selectedFloor: any;
  @Input() favoriteEquipmentList: any;
  @Input() categoryData: any;
  @Input() domotzUserId: any;
  @Input() floorScale: any = undefined;
  @Input() defaultFloor: any;
  @Output() saveEquipmentDetails = new EventEmitter();
  @Output() closeEquipment = new EventEmitter();
  @Output() spinnerSignal = new EventEmitter<any>();
  @Output() saveImageEquipmentDetails = new EventEmitter();
  @Output() equipCreateTicket = new EventEmitter();
  @Output() equipLinkTicket = new EventEmitter();
  @Output() selectDeviceFromAssociatedTab = new EventEmitter();
  @Output() showEquipmentModalForFavorites = new EventEmitter();
  @Output() openEquipmentDetail = new EventEmitter();
  @Output() inputCalled = new EventEmitter();
  @Output() removeFlagFromDevice = new EventEmitter();
  @Output() updateCoverage = new EventEmitter();
  @Input() isTicketSubmitter: any;
  @ViewChild('ngxSliderDirection') ngxSliderDirection!: SliderComponent;
  @ViewChild('ngxSliderAngle') ngxSliderAngle!: SliderComponent;
  @ViewChild('ngxSliderRange') ngxSliderRange!: SliderComponent;
  destroy$: Subject<boolean> = new Subject<boolean>();
  destroyPixie$: Subject<boolean> = new Subject<boolean>();
  retakePictureSubscription!: Subscription;
  takePictureSubscription!: Subscription;
  isSaving = false;

  topPosition = 30; // Default initial top value
  // equipmentDetails: { isFlag: boolean } | null = null; // Initialize as null to avoid undefined errors
  // fromProject: boolean | null = null; // Initialize as null or a default value



  equipmentDetailsForm: any;
  deviceType = DeviceTypes;
  publishedTooltipMessageText = '';
  isLiveSystemAccessForCustomer = true;
  codeForDeviceColor = CodeForDeviceColor;
  notWorkingText: any;
  operationalWithIssueText: any;
  operational: any;
  priorityItems: any;
  equipmentIPTypeList = EquipmentIPTypeList;
  equipmentLocationList = EquipmentLocationList;
  uploadedFile!: FileItem;
  invalidFileDragged = false;
  count = 0;
  showLoader = false;
  hasImage = false;
  tempAddedImages: any[] = [];
  uploadedFiles: any[] = [];
  fileUploadOptions!: FileUploadOptions;
  limitErrorMessage = FileUploadConstant.FILEWITHOTLIMITERRORMESSAGE;
  isLoaderShow = true;
  bsModalRef?: BsModalRef;
  bsModalRefPixie?: BsModalRef;
  imgs: any = [];
  loggedInUser: any;
  unitOfMeasurements: any = 'Feet'
  isFormDisabled = false;
  timer!: number;
  toolTipObj: TooltipModel = new TooltipModel();
  isFavoriteDataAvailable: any;
  createdTime!: string;
  monitoredDateTime!: string;
  updatedTime!: string;
  flaggedTime!: string;

  directionValue = 0;
  directionOptions: Options = {
    floor: 0,
    ceil: 360,
    showTicks: true,
    disabled: !this.isEdit,
    ticksArray: [0, 45, 90, 135, 180, 225, 270, 315, 360],
  };

  angleValue = 45;
  angleOptions: Options = {
    floor: 1,
    ceil: 360,
    disabled: !this.isEdit,
    showTicks: true,
    ticksArray: [1, 45, 90, 135, 180, 225, 270, 315, 360],
  };

  rangeValue = 1;
  rangeOptions: Options = {
    floor: 1,
    ceil: 100,
    disabled: !this.isEdit,
    showTicks: true,
    ticksArray: [4, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  };
  serviceTickets: any = [];
  associatedCount!: number;
  associatedTicketCount!: number;
  associatedClosedTicketCount!: number;
  associatedWithClosedCount!: number;
  associatedDeviceCount!: number;
  associatedAuditCount: any;
  associatedWithCompletedCount: any;
  hideCompletedAudit = true;
  userObj: any;
  canShowClosedTicket = true;
  isGlobalAdmin: any;
  isCustomerTech: any;
  statuses = Statuses;
  isEditMode = false;
  unlinkedServiceTickets: any;
  unlinkedTicket: any[] = [];
  countDuplicateName: any;
  nameSub!: Subscription;
  abbSub!: Subscription;
  countDuplicateAbbreviatedName: any;
  isProjectManager: any;
  isGlobalUser!: boolean;
  favChildCount: any;
  favOldObj: any;
  toolTipConnectionObj: TooltipModel = new TooltipModel();
  selectedTab!: string;
  cableSub!: Subscription;
  isCustomerAdmin: any;
  isCustomerManager: any;
  optionsE: any = {
    attributes: null,
    className: 'linkified',
    defaultProtocol: 'http',
    events: null,
    format: (value: any, type: any) => {
      return value;
    },
    formatHref: (href: any, type: any) => {
      return href;
    },
    ignoreTags: [],
    nl2br: false,
    tagName: (value: any, type: any) => {
      if (type === 'url') {
        return 'a';
      } else {
        return 'span'
      }
    },
    target: {
      url: '_blank'
    },
    validate: true
  };
  toolTipObjUpdate: TooltipModel = new TooltipModel();
  updateTimer!: number;
  isTicketAccessible = false;
  isDeviceSelectionChanged = false;
  isOpenAll = false;
  scrollTop: any;
  fileError = false;
  pixieOpenSub: any;
  cancelSubscription: any;
  discardPerformedSub: any;
  private previousImageIds = new Set<string>();
  isCompressing = false;

  constructor(private router: Router, private fb: FormBuilder, private eventService: EventService, private authService: AuthService, private cdRef: ChangeDetectorRef, private modalService: BsModalService, private http: HttpClient, private floorDataTransferService: FloorDataTransferService,
    private equipmentDetailService: EquipmentDetailsService, private fileUploadService: FileUploadService, private route: ActivatedRoute) {
    this.priorityItems = this.equipmentDetailService.getDropDownOptions('devicePriority', []);
    this.fileUploadOptions = {
      isMultiple: true,
      acceptExtensions: FileUploadConstant.ALLOWEDIMAGEFILES,
      maxSize: 50
    }
    this.cableSub = this.eventService.subscribe(EventEmitterType.cablePathChange, (r: any) => {
      this.equipmentDetailsForm.controls['cableCoordinates'].setValue(r.coordinates)
      this.cdRef.detectChanges();
    })

    this.discardPerformedSub = this.eventService.subscribe(EventEmitterType.discardPerformed, (r: any) => {
      if (r && r.images && r.images.length > 0) {
        this.equipmentDetails = _.cloneDeep(r)
        this.previousImageIds = new Set(this.equipmentDetails.images.map((img: any) => img.thumbnailAwsImageURL)); // Update tracked IDs
        this.prepareImageHandling();
        this.cdRef.detectChanges();
      }
    })

  }
  ngOnInit(): void {

    this.isFavoriteDataAvailable = !Utilities.isEmpty(this.favoriteEquipmentDetails);
    this.loggedInUser = this.authService.getUserKeyData();
    this.unitOfMeasurements = this.authService.getUserKeyData().unitOfMeasurements;
    this.isTicketAccessible = this.loggedInUser.isTicketing;
    this.isGlobalAdmin = this.loggedInUser.userRole === Roles.Administrator;
    this.isCustomerAdmin = this.loggedInUser.isAdmin;
    this.isCustomerTech = this.loggedInUser.customerRoleName === Roles.Technician;
    this.isProjectManager = this.loggedInUser.projectCustomerRoleName === Roles.Manager;
    this.isCustomerManager = this.loggedInUser.customerRoleName === Roles.Manager;
    this.isGlobalUser = this.authService.getUserKeyData().userRole === Roles.User;
    this.notWorkingText = Utilities.updateDisplayStatus(this.deviceStatusData, 'NotWorking');
    this.operationalWithIssueText = Utilities.updateDisplayStatus(this.deviceStatusData, 'OperationalWithIssue');
    this.operational = Utilities.updateDisplayStatus(this.deviceStatusData, 'Operational');
    const timeout = setTimeout(() => {
      this.setValidationCheck();
      if (this.equipmentDetails && this.equipmentDetails !== undefined && this.equipmentDetails !== null && this.equipmentDetails.type === 'device') {
        this.loadAccordionState();
        const savedScrollPosition = this.getScrollPosition();
        const accordionElement: any = document.getElementById('device-accordion-panel');
        if (accordionElement && accordionElement !== null) {
          accordionElement.scrollTo(0, savedScrollPosition);
        }
        // window.scrollTo(0, savedScrollPosition);
        this.router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
            const scrollPosition = accordionElement.scrollY || accordionElement.scrollTop || document.body.scrollTop || 0;
            this.saveScrollPosition(scrollPosition);
          }
        });
      }
      this.cdRef.detectChanges();
      clearTimeout(timeout);
    }, 0);

  }
  createForm() {
    this.equipmentDetailsForm = this.fb.group({
      id: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: true }],
      isUpdate: [false],
      uniqueId: [{ value: '', disabled: true }],
      typeName: [{ value: '', disabled: true }],
      name: ['', [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
      abbreviatedName: ['', Validators.maxLength(120)],
      serialNumber: [''],
      categoryName: [{ value: '', disabled: true }],
      partNumber: [''],
      dataType: [null],
      manufacturer: [''],
      description: ['', Validators.maxLength(4000)],
      instructions: ['', Validators.maxLength(4000)],
      installationNotes: ['', Validators.maxLength(4000)],
      installStatus: [{ value: EquipmentStatusForDevice.PLANNED }],
      installStatusCode: [4],
      installHours: ['', [ValidationService.negativeValueInstallHoursValidator]],
      price: ['', [ValidationService.negativeValueCostValidator]],
      deviceLocation: [null],
      // fieldOfView: [null],
      coverageArea: [false],
      coverageDirection: [null],
      dimension: [null], //used for coverage svg path
      dimensionWidth: [null],//used for coverage svg width
      dimensionScale: [null],//used for coverage svg scailing
      coverageAngle: [null],
      coverageRange: [null],
      coverageRangeSlider: [null],
      ipAddress: ['', [ValidationService.IPValidator]],
      macAddress: ['', [ValidationService.MACAddressValidator]],
      imageLeft: [''],
      imageTop: [''],
      buildingName: [{ value: '', disabled: true }],
      buildingAddress: [{ value: '', disabled: true }],
      buildingId: [{ value: '', disabled: true }],
      siteId: [{ value: '', disabled: true }],
      floorName: [{ value: '', disabled: true }],
      siteName: [{ value: '', disabled: true }],
      barcode: [''],
      status: [{ value: EquipmentStatusForDevice.OPERATIONAL }],
      statusCode: [3],
      installationDate: [''],
      laborWarrantyExpirationDate: [''],
      deviceWarrantyExpirationDate: [''],
      dueDate: [''],
      priority: [{ value: DevicePriority.MEDIUM }],
      height: [null, [ValidationService.negativeValueValidator]],
      deviceConnectedTo: ['', [Validators.maxLength(80)]],
      serviceTicketCount: [''],
      serviceTickets: [''],
      isEquipmentFavoriteForm: [false],
      isDeleted: [false],
      installedByUser: [null],
      assignedToUser: [null],
      autoUpdateDeviceStatus: ['By Monitoring Status'],
      networkSwitchLinkId: [null],
      networkSwitchLinkName: [null],
      networkSwitchLinkEquipments: [null],
      networkSwitchLinkFloorId: [null],
      networkSwitchLinkTempId: [null],
      port: [null, [Validators.maxLength(20)]],
      pinColor: [null],
      monitoredStatus: [''],
      flagNotes: ['', [Validators.required, Validators.maxLength(4000)]],
      isFlag: [null],
      flagCreatedBy: [null],
      flagCreatedDateTime: [null],
      isChildFlag: [null],
      isHub: [null],
      auditDeviceDetail: [null],
      cableCoordinates: [null],
      projectId: [null],
      orderWithinParent: [null],
      tempId: [null],
      isShowConnectionsFilter: [],
      parentId: [null],
      customDeviceId: [null, [Validators.maxLength(60)]],
      connectionLength: [0],
      budgetedCost: ['', ValidationService.negativeValueCostValidator]
    });
    this.serviceTickets = this.equipmentDetails && this.equipmentDetails.serviceTickets ? this.equipmentDetails.serviceTickets : [];
    this.isEditMode = !this.equipmentDetails.isNew;
    if (this.equipmentDetails) {
      this.unlinkedServiceTickets = this.filterTickets().length;
    }
    const timeOut = setTimeout(() => {
      this.setValidationCheck();
      clearTimeout(timeOut)
    }, 0);
    if (!this.isEdit || this.permissions.isViewOnly || this.equipmentDetails.isArchived || this.equipmentDetails.isLiveDevice || (this.fromProject && this.equipmentDetails.projectId === null)) {
      this.isFormDisabled = true;
      this.equipmentDetailsForm.disable()
      this.sliderDisable(this.isFormDisabled)
    } else {
      this.isFormDisabled = false;
      this.equipmentDetailsForm.enable()
      this.sliderDisable(this.isFormDisabled)
    }
    this.disableFormFields();
  }
  createTaskForm() {
    this.equipmentDetailsForm = this.fb.group({
      id: [''],
      type: [''],
      isUpdate: [false],
      name: ['', [Validators.required, Validators.maxLength(120), ValidationService.onlyWhiteSpaceValidator]],
      abbreviatedName: ['', Validators.maxLength(120)],
      categoryName: [''],
      typeName: [''],
      installHours: ['', [ValidationService.negativeValueInstallHoursValidator]],
      dueDate: ['', [ValidationService.negativeValueDueDateHoursValidator]],
      installationDate: [],
      installedByUser: [],
      assignedToUser: [],
      imageLeft: [''],
      imageTop: [''],
      priority: [DevicePriority.Medium],
      description: [],
      installStatus: [EquipmentStatusForDevice.PLANNED],
      pinColor: [null],
      dataType: [null],
      flagNotes: ['', [Validators.required]],
      isFlag: [''],
      isChildFlag: [''],
      status: [''],
      projectId: [null],
      orderWithinParent: [null],
      tempId: [null],
      budgetedCost: ['', ValidationService.negativeValueCostValidator],
      instructions: ['', Validators.maxLength(4000)],
      installationNotes: ['', Validators.maxLength(4000)],
    });
    if (this.equipmentDetails) {
      this.equipmentDetailsForm.patchValue(this.equipmentDetails)
    }
    const timeOut = setTimeout(() => {
      this.setValidationCheck();
      clearTimeout(timeOut)
    }, 0);
    if (!this.isEdit || this.permissions.isViewOnly || this.equipmentDetails.isArchived || this.equipmentDetails.isLiveDevice || (this.fromProject && this.equipmentDetails.projectId === null)) {
      this.isFormDisabled = true;
      this.equipmentDetailsForm.disable()
    } else {
      this.isFormDisabled = false;
      this.equipmentDetailsForm.enable()
    }
  }
  removeClassFromH2() {
    const timeOut = setTimeout(() => {
      this.isDeviceSelectionChanged = false;
      this.cdRef.detectChanges();
      clearTimeout(timeOut);
    }, 1000);
  }
  ngOnChanges(changes: SimpleChanges): void {
    const highlight = localStorage.getItem('syncStarted');
    
    if (!this.hasChanges(changes)) {
      return;
    }

    if (changes['isEdit'] && this.equipmentDetailsForm) {
      this.hideFileValidationMessage = true;
      this.handleIsEditChange(changes['isEdit'].currentValue);
    }

    if (changes['equipmentDetails']) {
      this.handleEquipmentDetailsChange(changes['equipmentDetails'], highlight);
    }

    if (changes['favoriteEquipmentDetails']) {
      this.handleFavoriteEquipmentDetailsChange(changes['favoriteEquipmentDetails'], highlight);
    }

    if (changes['netWorkSwitches']) {
      this.netWorkSwitches = changes['netWorkSwitches'].currentValue;
    }

    this.getLength();
  }

  private hasChanges(changes: SimpleChanges): boolean {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        const { previousValue, currentValue } = changes[propName];
        if (!_.isEqual(previousValue, currentValue)) {
          setTimeout(() => {
            if (!this.isEdit || this.permissions.isViewOnly || this.equipmentDetails.isArchived || this.equipmentDetails.isLiveDevice || (this.fromProject && this.equipmentDetails.projectId === null)) {
              this.isFormDisabled = true;
              this.equipmentDetailsForm.disable()
              this.sliderDisable(this.isFormDisabled)
            } else {
              this.isFormDisabled = false;
              this.equipmentDetailsForm.enable()
              this.sliderDisable(this.isFormDisabled)
            }
          }, 0);
          return true;
        } else if (propName === 'equipmentDetails' && previousValue !== undefined) {

          this.equipmentDetailsForm.patchValue({
            imageLeft: this.equipmentDetails.imageLeft,
            imageTop: this.equipmentDetails.imageTop,
            isFlag: this.equipmentDetails.isFlag,
            isFlagAdd: this.equipmentDetails.isFlagAdd,
            isChildFlag: this.equipmentDetails.isChildFlag,
            flagNotes: this.equipmentDetails.flagNotes,
            flagCreatedDateTime: this.equipmentDetails.flagCreatedDateTime !== null ? this.equipmentDetails.flagCreatedDateTime : null,
            connectionLength: parseFloat(this.equipmentDetails.connectionLength),
            cableCoordinates: this.equipmentDetails.cableCoordinates,
            coverageArea: this.equipmentDetails.coverageArea,
            coverageDirection: this.equipmentDetails.coverageDirection,
            coverageAngle: this.equipmentDetails.coverageAngle,
            coverageRange: this.equipmentDetails.coverageRange,
          });
          this.serviceTickets = this.equipmentDetails && this.equipmentDetails.serviceTickets ? this.equipmentDetails.serviceTickets : [];
          const timeOut = setTimeout(() => {
            this.setValidationCheck();
            clearTimeout(timeOut)
          }, 0);
          if (!this.isEdit || this.permissions.isViewOnly || this.equipmentDetails.isArchived || this.equipmentDetails.isLiveDevice || (this.fromProject && this.equipmentDetails.projectId === null)) {
            this.isFormDisabled = true;
            this.equipmentDetailsForm.disable()
            this.sliderDisable(this.isFormDisabled)
          } else {
            this.isFormDisabled = false;
            this.equipmentDetailsForm.enable()
            this.sliderDisable(this.isFormDisabled)
          }
          this.disableFormFields();
          this.preserveLogs();
          this.getLength();
        }
      }
    }
    return false;
  }

  private handleIsEditChange(isEdit: boolean): void {
    this.isFormDisabled = !isEdit;
    const formEnabled = isEdit && !this.equipmentDetails.isArchived && !this.equipmentDetails.isLiveDevice;

    if (formEnabled) {
      this.equipmentDetailsForm.enable();
    } else {
      this.isFormDisabled = true;
      this.equipmentDetailsForm.disable();
      this.disableFormFields();
    }

    this.sliderDisable(this.isFormDisabled);
    this.disableFormFields();

    setTimeout(() => this.onScroll(), 0);
  }

  private handleEquipmentDetailsChange(change: SimpleChange, highlight: string | null): void {

    this.equipmentDetails = change.currentValue;
    this.createForm();
    this.setValidationCheck();
    this.patchFormWithDetails();
    if (change.currentValue.images && change.currentValue.images.length > 0) {
      const newImages = change.currentValue.images;
      // Check if images have changed
      if (this.hasImageChanges(newImages) && (change.firstChange || (change.previousValue && change.currentValue.id !== change.previousValue.id))) {
        this.previousImageIds = new Set(newImages.map((img: any) => img.thumbnailAwsImageURL)); // Update tracked IDs
        this.prepareImageHandling();
      } else if (change.previousValue && !change.firstChange && change.currentValue.id !== change.previousValue.id) {
        this.previousImageIds = new Set(newImages.map((img: any) => img.thumbnailAwsImageURL)); // Update tracked IDs
        this.prepareImageHandling();
      }
    } else if (change.currentValue.images && change.currentValue.images.length === 0) {
      this.uploadedFiles = [];
      this.hasImage = false;
    }
    this.preserveLogs();
    if (this.shouldUpdateDeviceSelection(change, highlight)) {
      this.isDeviceSelectionChanged = true;
      this.removeClassFromH2();
      this.floorDataTransferService.updateDeviceSelection(false);
      localStorage.removeItem('syncStarted');
    }

    setTimeout(() => this.onScroll(), 0);
  }
  hasImageChanges(newImages: any[]): boolean {
    const newIds = new Set(newImages.map(img => img.thumbnailAwsImageURL));
    const oldIds = this.previousImageIds;

    // Check for added/removed images
    if (newIds.size !== oldIds.size || [...newIds].some(id => !oldIds.has(id))) {
      return true;
    }

    // Optionally, add logic to check for updates in existing images
    return newImages.some(img => img.isModified);
  }
  private handleFavoriteEquipmentDetailsChange(change: SimpleChange, highlight: string | null): void {
    this.favoriteEquipmentDetails = change.currentValue;
    this.favOldObj = { ...this.favoriteEquipmentDetails };

    this.favoriteEquipmentDetails.type === DeviceTypes.Device ? this.createFavoriteForm() : this.createTaskFavoriteForm();
    this.favChildCount = this.favoriteEquipmentChildDetailsCount();

    if (this.shouldUpdateDeviceSelection(change, highlight)) {
      this.isDeviceSelectionChanged = true;
      this.removeClassFromH2();
      this.floorDataTransferService.updateDeviceSelection(false);
      this.cdRef.detectChanges();
    }

    localStorage.removeItem('syncStarted');
  }

  private prepareImageHandling(): void {
    this.imgs = [];
    this.uploadedFiles = [];

    if (!Utilities.isEmpty(this.equipmentDetails.images)) {
      this.equipmentDetails.images.forEach((img: any) => {
        img.isImageExsits = false;
        img.isLoading = true;
        img.imageCheckCnt = 0;
      });

      this.checkEquipmentHasImage();
      this.uploadedFiles = _.cloneDeep(
        this.equipmentDetails.images.sort((a: any, b: any) => new Date(b.createdDateTime).getTime() - new Date(a.createdDateTime).getTime())
      );
    } else {
      this.hasImage = false;
    }

    this.hideFileValidationMessage = true;
  }

  private patchFormWithDetails(): void {
    this.equipmentDetailsForm.patchValue(this.equipmentDetails);

    if (this.equipmentDetails.coverageArea) {
      this.directionValue = this.equipmentDetails.coverageDirection;
      this.angleValue = this.equipmentDetails.coverageAngle;
      this.rangeValue = this.equipmentDetails.coverageRange;
    }
  }

  private shouldUpdateDeviceSelection(change: SimpleChange, highlight: string | null): boolean {
    return this.floorDataTransferService.isFavDeviceSelected ||
      (change.previousValue && !change.firstChange && change.currentValue.id !== change.previousValue.id && !highlight);
  }


  saveDetails(isBack: boolean) {
    if (this.equipmentDetails && this.equipmentDetails.isLiveDevice) {
      return;
    }
    if (!this.isFormDisabled) {
      if (this.isFavorite) {
        const equipmentDetails = this.equipmentDetailsForm.value as any;
        equipmentDetails.name = _.cloneDeep(Utilities.removeWhiteSpace(equipmentDetails.name));
        if (equipmentDetails.name == '' || equipmentDetails.name == null || equipmentDetails.name == undefined) {
          equipmentDetails.name = this.favoriteEquipmentDetails.name
          this.equipmentDetailsForm.controls['name'].setValue(equipmentDetails.name);
        }
        if (this.equipmentDetailsForm.controls['price']?.invalid) {
          equipmentDetails.price = this.favOldObj.price;
          this.equipmentDetailsForm.controls['price'].setValue(this.favOldObj.price);
        }
        if (this.equipmentDetailsForm.controls['budgetedCost']?.invalid) {
          equipmentDetails.budgetedCost = this.favOldObj.budgetedCost;
          this.equipmentDetailsForm.controls['budgetedCost'].setValue(this.favOldObj.budgetedCost);
        }
        equipmentDetails.id = this.favoriteEquipmentDetails.id;
        equipmentDetails.equipment = this.favoriteEquipmentDetails.equipment;
        equipmentDetails.type = this.favoriteEquipmentDetails.type || DeviceTypes.Device;

        equipmentDetails.categoryName = this.favoriteEquipmentDetails.categoryName;
        equipmentDetails.typeName = this.favoriteEquipmentDetails.typeName;
        equipmentDetails.index = this.favoriteEquipmentDetails.index;
        equipmentDetails.childFavouriteEquipments = this.favoriteEquipmentDetails.childFavouriteEquipments || [];
        equipmentDetails.orderNumber = this.favoriteEquipmentDetails.orderNumber;
        if (equipmentDetails.dataType === 'null') {
          equipmentDetails.dataType = null;
        }
        if (equipmentDetails.deviceLocation === 'null') {
          equipmentDetails.deviceLocation = null;
        }
        let isBackClickedWithNoDataChange = false;
        if (this.equipmentDetailsForm.dirty) {
          isBackClickedWithNoDataChange = true;
        }
        this.hideFileValidationMessage = true;
        const transformed = _.transform(equipmentDetails, (result: any, value: any, key: any) => {
          result[key] = typeof value === "string" ? value.trim() : value
        })
        this.saveEquipmentDetails.emit({ equipmentDetails: transformed, isBackClickedWithNoDataChange: isBackClickedWithNoDataChange, showEquipment: isBack });
      } else {
        this.rollBackFieldsWithDifference().then((r: any) => {
          if (r.isSave) {
            this.hideFileValidationMessage = true;
            this.saveEquipmentDetails.emit(r.diffOfEquipments);
          }
        })
      }
    }
  }
  ngOnDestroy(): void {
    this.isDeviceSelectionChanged = false;
    if (this.retakePictureSubscription) {
      this.retakePictureSubscription.unsubscribe();
    }

    if (this.takePictureSubscription) {
      this.takePictureSubscription.unsubscribe();
    }
    if (this.nameSub) {
      this.nameSub.unsubscribe();
    }
    if (this.abbSub) {
      this.abbSub.unsubscribe();
    }
    if (this.cableSub) {
      this.cableSub.unsubscribe();
    }
    if (this.discardPerformedSub) {
      this.discardPerformedSub.unsubscribe();
    }

    this.destroy$.next(true);
    this.destroy$.complete();

  }
  disableFormFields() {
    this.equipmentDetailsForm.controls.id.disable();
    this.equipmentDetailsForm.controls.typeName.disable();
    this.equipmentDetailsForm.controls.type.disable();
    this.equipmentDetailsForm.controls.typeName.disable();
    this.equipmentDetailsForm.controls.uniqueId.disable();
    this.equipmentDetailsForm.controls.categoryName.disable();
    this.equipmentDetailsForm.controls.buildingId.disable();
    this.equipmentDetailsForm.controls.buildingName.disable();
    this.equipmentDetailsForm.controls.buildingAddress.disable();
    this.equipmentDetailsForm.controls.siteId.disable();
    this.equipmentDetailsForm.controls.siteName.disable();
    this.equipmentDetailsForm.controls.floorName.disable();
    this.equipmentDetailsForm.controls.connectionLength.disable();
  }

  closeEquipmentDetail() {
    if (this.isFavorite) {
      this.saveDetails(false);
    } else {
      this.closeEquipment.emit();
      this.isDeviceSelectionChanged = false;
    }
  }


  fileUploaded(uploadedFiles: any[]) {
    this.eventService.broadcast(EventEmitterType.isImageLoadingLocal, { isImageLoadingLocal: true })
    this.equipmentDetailsForm.markAsDirty()
    this.invalidFileDragged = false;
    this.count = 0;
    this.isCompressing = true;
    const tempDevice = _.cloneDeep(this.equipmentDetails);
    uploadedFiles.forEach((uploadedFile: any, index: any) => {
      this.uploadFileLocally(uploadedFile, uploadedFiles, tempDevice, (uploadedFiles.length - 1) === index);
    })
  }

  uploadFileLocally(uploadedFile: any, fileArray: any, tempDevice: any, isLastIndex = true) {
    if (!Utilities.isNull(uploadedFile) && !Utilities.isNull(uploadedFile.fileData)) {
      this.equipmentDetails.isNewFile = true;
      uploadedFile.fileData.extension = uploadedFile.extension
      const reader = new FileReader();
      reader.onloadend = async (e) => {
        this.equipmentDetails.awsImageURL = reader.result as string;
        this.commonImageCompression(tempDevice, uploadedFile, true, fileArray, isLastIndex);
      };
      reader.readAsDataURL(uploadedFile.fileData);
    }
  }

  async commonImageCompression(tempDevice: any, uploadedFile: any, isToUploadLocal?: any, fileArray?: any, isLastIndex = true, isFileSelected = true) {
    let url: any;
    if (!isToUploadLocal || !isFileSelected) {
      url = tempDevice
    } else {
      url = this.equipmentDetails.awsImageURL === undefined ? tempDevice : this.equipmentDetails.awsImageURL
    }
    await this.convertBlob(url, uploadedFile.fileData.type).then(async res => {
      await Utilities.getCompressedImage(res, 3840, 2160).then((compressed: any) => {
        if (compressed !== undefined) {
          url = compressed.toString();
        }
        if (isToUploadLocal) {
          this.equipmentDetails.awsImageURL = url
          this.resizeAttachImage(this.equipmentDetails, this.equipmentDetails.awsImageURL, uploadedFile, fileArray, isLastIndex);
        } else {
          this.showLoader = false;
          const fileTest = {
            awsImageURL: url,
            file: uploadedFile,
            fileName: '',
            isDeleted: false,
            isNewFile: true,
            mode: 'Library Upload',
            stage: this.floorStage,
            tempId: uploadedFile.tempId
          };
          this.openImagePopup(fileTest, null, true);
        }
      });
    })
  }
  resizeAttachImage(tempDevice: any, image: any, uploadedFile: any, fileArray: any, isLastIndex = true) {
    const fileTest = {
      awsImageURL: image,
      thumbnailAwsImageURL: image,
      fileData: uploadedFile,
      fileName: '',
      fileLocation: moment().toDate().getTime() + '' + uploadedFile.extension,
      isDeleted: false,
      isNewFile: true,
      mode: 'Library Upload',
      stage: this.floorStage,
      imgId: (Math.random() + 1).toString(36).substring(7)
    };
    const timeout = setTimeout(() => {
      this.count = this.count + 1
      if (tempDevice.id === this.equipmentDetails.id) {
        const updateImage = _.cloneDeep(this.uploadedFiles);
        this.tempAddedImages.push(updateImage.unshift(fileTest) - 1);
        this.uploadedFiles = _.cloneDeep(updateImage);

      } else {
        const tempFile: any = fileTest
        tempFile.fileLocation = new Date().getTime() + this.count + fileTest.fileData.extension;
        this.spinnerSignal.emit({ file: tempFile, device: tempDevice })
      }
      if (this.count === fileArray.length) {
        this.checkUpdatedImages(true, false, false);
        this.spinnerSignal.emit(false)
        this.eventService.broadcast(EventEmitterType.isImageLoadingLocal, { isImageLoadingLocal: false })
        this.isCompressing = false;
      }
      clearTimeout(timeout);
    }, 0);

  }
  convertBlob(res: any, type: any) {
    return new Promise(resolve => {
      let blob;
      if (res) {
        const contentType = type;
        res = res.toString().replace(/data:image\/([a-z]*)?;base64,/, '');
        blob = (b64toBlob as any)(res, contentType);
        resolve(blob);
      }
    });
  }

  openImagePopup(uploadedFile: any, index?: any, isFrpmDragAndDrop?: any) {
    if (this.isEdit && !this.isFormDisabled) {
      let fromDrag = false;
      if (!index && isFrpmDragAndDrop) {
        fromDrag = true;
        const imgObj = {
          awsImageURL: uploadedFile.awsImageURL,
          fileData: uploadedFile.file,
          fileName: '',
          isDeleted: false,
          isNewFile: true,
          thumbnailAwsImageURL: uploadedFile.awsImageURL,
          source: uploadedFile.source,
          mode: uploadedFile.mode,
          stage: this.floorStage,
          tempId: uploadedFile.tempId,
          imgId: (Math.random() + 1).toString(36).substring(7)
        }
        this.uploadedFiles.unshift(imgObj)
        index = this.uploadedFiles.length - 1
      } else {
        const initialState: ModalOptions = {
          initialState: {
            image: uploadedFile.awsImageURL,
            file: uploadedFile,
            caption: uploadedFile.fileName,
            equipment: this.equipmentDetails,
            canUpdate: this.permissions.canUpdateDevice,
            isSliderShow: !this.isEdit,
            index: index,
            isCanEdit: this.isEdit,
            isFromFloorPlan: true,
            showImageEditorForBuilding: false,
            projectId: this.fromProject,
            canResizePlanMarkup: true
          }, class: 'modal-page-cover modal-markup'
        };
        this.bsModalRef = this.modalService.show(PixieComponent, initialState);
        const pixieSub = this.bsModalRef.content.saveImage.subscribe((res: any) => {
          if (res) {
            let index;
            if (uploadedFile.imgId) {
              index = this.uploadedFiles.findIndex(e => e.imgId === uploadedFile.imgId);
            } else if (uploadedFile.tempId) {
              index = this.uploadedFiles.findIndex(e => e.tempId === uploadedFile.tempId);
            } else {
              index = this.uploadedFiles.findIndex(e => e.id === uploadedFile.id);
            }
            if (index > -1 && !Utilities.isNull(res) && !Utilities.isNull(res.image.fileData)) {
              const filedata = res.image.fileData;
              this.uploadedFiles[index].awsImageURL = res.image.awsImageURL;
              this.uploadedFiles[index].fileLocation = res.image.fileName === undefined ? Utilities.getCurrentDateTime() + '.' + res.extension : res.image.fileName;
              this.uploadedFiles[index].thumbnailAwsImageURL = res.image.awsImageURL;
              this.uploadedFiles[index].fileData = filedata;
              this.uploadedFiles[index].fileName = (res.captionDetails.name === null || res.captionDetails.name === undefined) ? "" : res.captionDetails.name;
              if (this.uploadedFiles[index].imgId === undefined && Utilities.isNull(this.uploadedFiles[index].id)) {
                this.uploadedFiles[index].imgId = (Math.random() + 1).toString(36).substring(7)
              }
              if (!Utilities.isNull(res) && !Utilities.isNull(res.image.fileData) && this.uploadedFiles[index].fileLocation === undefined) {
                const filedata = res.image.fileData;
                this.uploadedFiles[index].awsImageURL = res.image.awsImageURL;
                this.uploadedFiles[index].fileLocation = res.image.fileName === undefined ? Utilities.getCurrentDateTime() + '.' + res.extension : res.image.fileName;
                this.uploadedFiles[index].thumbnailAwsImageURL = res.image.awsImageURL;
                this.uploadedFiles[index].fileData = filedata;
                this.uploadedFiles[index].fileName = (res.captionDetails.name === null || res.captionDetails.name === undefined) ? "" : res.captionDetails.name;
                if (this.uploadedFiles[index].imgId === undefined && Utilities.isNull(this.uploadedFiles[index].id)) {
                  this.uploadedFiles[index].imgId = (Math.random() + 1).toString(36).substring(7)
                }
                if (!Utilities.isNull(this.uploadedFiles[index].id) || this.uploadedFiles[index].isNewFile) {
                  const flag = this.uploadedFiles[index].fileLocation.split('/');
                  this.uploadedFiles[index].fileLocation = this.uploadedFiles[index].fileLocation.split('/')[(flag.length - 1)];
                  this.uploadedFiles[index].isUpdate = true;
                  this.uploadedFiles[index].isNewFile = true;
                }
              } else {
                this.uploadedFiles[index].fileName = Utilities.isNull(res) ? '' : res.captionDetails.name;
                this.uploadedFiles[index].isUpdate = true;
              }
            }
            pixieSub.unsubscribe();
            this.checkUpdatedImages(true, true, false);
          }
        });
        this.bsModalRef?.onHidden?.subscribe(() => {
          pixieSub.unsubscribe();
        })
      }
    } else {
      const initialState: ModalOptions = {
        initialState: {
          file: uploadedFile,
          image: uploadedFile.awsImageURL,
          equipment: this.equipmentDetails,
        }, class: 'modal-page-cover modal-markup', ignoreBackdropClick: true
      };
      this.bsModalRef = this.modalService.show(ViewImageComponent, initialState);
    }
  }

  fileUploadFail() {
    this.invalidFileDragged = false;
    this.hideFileValidationMessage = false;
    this.eventService.broadcast(EventEmitterType.isImageLoadingLocal, { isImageLoadingLocal: false })
  }
  openCameraPopup() {
    if (this.destroyPixie$ && this.destroyPixie$ !== null) {
      this.destroyPixie$.next(true);
      this.destroyPixie$.complete();
    }

    if (this.isFormDisabled) {
      return;
    }

    this.invalidFileDragged = false;
    this.hideFileValidationMessage = true;

    const initialState: ModalOptions = {
      initialState: {
        equipmentDetails: this.equipmentDetails,
        stage: this.floorStage,
        isOpen: 'editImage'
      },
      class: 'modal-full-popup',
      ignoreBackdropClick: true
    };

    // Show the camera modal
    this.bsModalRef = this.modalService.show(TakePictureFromCameraComponent, initialState);

    const timeOut = setTimeout(() => {
      if (this.bsModalRef?.content?.event) {
        // Clean up existing subscription if present
        this.takePictureSubscription?.unsubscribe();

        this.takePictureSubscription = this.bsModalRef.content.event.subscribe((r: any) => {
          this.bsModalRef?.hide();

          if (r) {
            this.isSaving = false; // Reset flag after saving
            this.openPixieEditor(r);
          }
        });

        // Handle cancel event
        this.cancelSubscription?.unsubscribe();
        this.cancelSubscription = this.bsModalRef.content.cancel?.subscribe(() => {
          this.cleanupSubscriptions();
          this.bsModalRef?.hide();
          this.bsModalRefPixie?.hide();
        });
      }
      clearTimeout(timeOut);
    }, 0);
  }

  openPixieEditor(r: any) {
    if (this.isSaving) return; // Prevent duplicate saves
    this.isSaving = true;

    r['canResizePlanMarkup'] = true;

    const pixieInitialState: ModalOptions = {
      initialState: r,
      class: 'modal-page-cover'
    };

    this.bsModalRefPixie = this.modalService.show(PixieComponent, pixieInitialState);

    // Clean up previous subscriptions before setting new ones
    this.pixieOpenSub?.unsubscribe();
    this.retakePictureSubscription?.unsubscribe();
    this.cancelSubscription?.unsubscribe();

    // Handle save image event
    this.pixieOpenSub = this.bsModalRefPixie.content.saveImage?.subscribe(async (res: any) => {
      if (res) {
        await this.convertBlob(res.image.awsImageURL, res.image.fileData.type).then(async (resBlob) => {
          await Utilities.getCompressedImage(resBlob, 3840, 2160).then((compressed: any) => {
            if (compressed !== undefined) {
              compressed = compressed.toString();
            }

            const readyImage = {
              awsImageURL: compressed,
              fileData: res.image.fileData,
              fileName: res.image.fileName,
              fileLocation: res.image.fileName,
              isDeleted: false,
              isNewFile: true,
              stage: this.floorStage,
              mode: 'Photo Capture',
              thumbnailAwsImageURL: res.image.awsImageURL,
              imgId: (Math.random() + 1).toString(36).substring(7)
            };

            readyImage.fileName = res.captionDetails.name || '';
            const updateImage = _.cloneDeep(this.uploadedFiles);
            this.tempAddedImages.push(updateImage.unshift(readyImage) - 1);
            this.uploadedFiles = _.cloneDeep(updateImage);
            this.checkUpdatedImages(true, false, false);
          });
        });

        this.isSaving = false; // Reset flag after saving
        this.cleanupSubscriptions();
      }
    });

    // Handle retake picture event
    this.retakePictureSubscription = this.bsModalRefPixie.content.retakePicture?.subscribe(() => {
      this.cleanupSubscriptions();
      this.bsModalRef?.hide();
      this.bsModalRefPixie?.hide();
      this.openCameraPopup();
    });

    // Handle cancel event
    this.cancelSubscription = this.bsModalRefPixie.content.cancel?.subscribe(() => {
      this.cleanupSubscriptions();
      this.bsModalRef?.hide();
      this.bsModalRefPixie?.hide();
    });
  }

  cleanupSubscriptions() {
    this.takePictureSubscription?.unsubscribe();
    this.pixieOpenSub?.unsubscribe();
    this.retakePictureSubscription?.unsubscribe();
    this.cancelSubscription?.unsubscribe();
    this.isSaving = false; // Reset the flag on cleanup
  }

  // Extracted method to handle image editing
  handleImageEdit(r: any) {
    r['canResizePlanMarkup'] = true;
    const pixieInitialState: ModalOptions = {
      initialState: r,
      class: 'modal-page-cover'
    };

    this.bsModalRefPixie = this.modalService.show(PixieComponent, pixieInitialState);

    let pixieOpenSub = this.bsModalRefPixie.content.saveImage?.subscribe(async (res: any) => {
      if (res) {
        await this.convertBlob(res.image.awsImageURL, res.image.fileData.type).then(async (resBlob) => {
          await Utilities.getCompressedImage(resBlob, 3840, 2160).then((compressed: any) => {
            if (compressed !== undefined) {
              compressed = compressed.toString();
            }

            const readyImage = {
              awsImageURL: compressed,
              fileData: res.image.fileData,
              fileName: res.image.fileName,
              fileLocation: res.image.fileName,
              isDeleted: false,
              isNewFile: true,
              stage: this.floorStage,
              mode: 'Photo Capture',
              thumbnailAwsImageURL: res.image.awsImageURL,
              imgId: (Math.random() + 1).toString(36).substring(7)
            };

            readyImage.fileName = res.captionDetails.name || '';
            const updateImage = _.cloneDeep(this.uploadedFiles);
            this.tempAddedImages.push(updateImage.unshift(readyImage) - 1);
            this.uploadedFiles = _.cloneDeep(updateImage);
            this.checkUpdatedImages(true, false, false);
          });
        });
        pixieOpenSub?.unsubscribe();
      }
    });

    // Handle retake and cancel events
    this.bsModalRefPixie.content.retakePicture?.subscribe(() => {
      pixieOpenSub?.unsubscribe();
      this.bsModalRef?.hide();
      this.bsModalRefPixie?.hide();
      this.openCameraPopup();
    });

    this.bsModalRefPixie.content.cancel?.subscribe(() => {
      pixieOpenSub?.unsubscribe();
      this.bsModalRef?.hide();
      this.bsModalRefPixie?.hide();
    });
  }

  openImageDeletePopup(deleteImg: any, index: any) {
    const dataReplacer = {
      '{{#imgName}}': deleteImg.fileName === null ? "" : _.escape(deleteImg.fileName)
    }
    const bodyText: any = _.cloneDeep(ImgDelete)
    const modalData: any = Utilities.contentReplacer(dataReplacer, bodyText);
    const initialState: ModalOptions = {
      initialState: modalData, class: bodyText.dialogClass
    };
    this.bsModalRef = this.modalService.show(CommonPopupComponent, initialState);
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res !== 'Cancel') {
        let tmpIndex = index;
        if (deleteImg && deleteImg.id > 0) {
          tmpIndex = this.uploadedFiles.findIndex((x: any) => x.id === deleteImg.id);
        } else if (deleteImg && deleteImg.imgId !== null && deleteImg.isNewFile) {
          tmpIndex = this.uploadedFiles.findIndex((x: any) => x.imgId === deleteImg.imgId);
        }
        this.uploadedFiles[tmpIndex].isDeleted = true;
        if (this.imgs && this.imgs.filter((z: any) => (z.id === deleteImg.id || deleteImg.isNewFile).length === 0)) {
          this.checkUpdatedImages(false, false, true);
        }
      }
    });
  }
  checkUpdatedImages(isNew = false, isUpdate = false, isDeleted = false) {
    const updatedImgArr: any = this.uploadedFiles;
    // this.equipmentDetails.images = [];
    this.equipmentDetails.images = updatedImgArr.filter((k: any) => !k.isDeleted);
    const imgData: any = { id: this.equipmentDetails.id, type: this.equipmentDetails.type };
    if (this.equipmentDetails.parentId !== null) {
      imgData["parentId"] = this.equipmentDetails.parentId
    }
    if (isDeleted) {
      const delImages = this.uploadedFiles.filter((x: any) => x.isDeleted);
      if (delImages && delImages.length > 0) {
        this.imgs = [];
        delImages.forEach((element: any) => {
          if (element.id > 0) {
            this.imgs.push({ id: element.id, isDeleted: true })
            imgData['deletedImages'] = this.imgs
          } else {
            this.imgs.push({ id: element.imgId, isDeleted: true })
            imgData['deletedImages'] = this.imgs
          }
        });
      }
    } else if (isUpdate) {
      const updatedImages = this.uploadedFiles.filter((x: any) => ((x.id > 0 && x.isUpdate && !x.isNewFile && !x.isDeleted) || (x.imgId !== null && x.isUpdate && x.isNewFile && !x.isDeleted)));
      if (updatedImages && updatedImages.length > 0) {
        imgData['updatedImages'] = updatedImages
      }
    } else if (isNew) {
      const newImages = this.uploadedFiles.filter((x: any) => x.isNewFile && !x.isDeleted);
      if (newImages && newImages.length > 0) {
        imgData['newImages'] = newImages
      }
    }
    this.checkEquipmentHasImage();

    // this.uploadedFiles = _.cloneDeep(this.equipmentDetails.images);
    this.saveImageEquipmentDetails.emit(imgData);
    this.cdRef.detectChanges();
  }

  resetPathway() {
    this.equipmentDetailsForm.controls['cableCoordinates'].setValue(null);
    this.equipmentDetailsForm.controls['isUpdate'].setValue(true);
    this.saveDetails(false);
  }

  changePinColor(newColor: any) {
    if (this.isEdit) {
      this.equipmentDetailsForm.controls['pinColor'].setValue(newColor);
      this.saveDetails(false);
    }
  }

  changeFavPinColor(newColor: any) {
    if (this.isEdit) {
      this.equipmentDetailsForm.controls['pinColor'].setValue(newColor);
      this.saveDetails(true);
    }
  }

  rollBackFieldsWithDifference() {
    return new Promise((resolve, reject) => {
      if (this.equipmentDetailsForm.controls['name'].value === null || this.equipmentDetailsForm.controls['name'].value.trim() === "") {
        this.equipmentDetailsForm.controls['name'].setValue(this.equipmentDetails.name);
      } else if (this.equipmentDetailsForm.controls['ipAddress'] && this.equipmentDetailsForm.controls['ipAddress'].invalid) {
        this.equipmentDetailsForm.controls['ipAddress'].setValue(this.equipmentDetails.ipAddress);
      } else if (this.equipmentDetailsForm.controls['macAddress'] && this.equipmentDetailsForm.controls['macAddress'].invalid) {
        this.equipmentDetailsForm.controls['macAddress'].setValue(this.equipmentDetails.macAddress);
      } else if (this.equipmentDetailsForm.controls['height'] && this.equipmentDetailsForm.controls['height'].invalid && this.equipmentDetailsForm.controls['height'].value !== undefined && this.equipmentDetailsForm.controls['height'].value !== null) {
        this.equipmentDetailsForm.controls['height'].setValue(this.equipmentDetails.height);
      } else if (this.equipmentDetailsForm.controls['price'] && this.equipmentDetailsForm.controls['price'].invalid) {
        this.equipmentDetailsForm.controls['price'].setValue(this.equipmentDetails.price);
      } else if (this.equipmentDetailsForm.controls['budgetedCost'] && this.equipmentDetailsForm.controls['budgetedCost'].invalid) {
        this.equipmentDetailsForm.controls['budgetedCost'].setValue(this.equipmentDetails.budgetedCost);
      } else if (this.equipmentDetailsForm.controls['installHours'] && this.equipmentDetailsForm.controls['installHours'].invalid) {
        this.equipmentDetailsForm.controls['installHours'].setValue(this.equipmentDetails.installHours);
      } else if ((this.equipmentDetails.isFlagAdd || this.equipmentDetails.isFlag) && this.equipmentDetails.flagNotes !== "" && this.equipmentDetails.flagNotes !== null && this.equipmentDetails.flagNotes !== undefined && (this.equipmentDetailsForm.controls['flagNotes'].value === null || this.equipmentDetailsForm.controls['flagNotes'].value.trim() === "")) {
        this.equipmentDetailsForm.controls['flagNotes'].setValue(this.equipmentDetails.flagNotes);
      }
      if (!Utilities.isEmpty(this.equipmentDetailsForm.controls['customDeviceId'] && this.equipmentDetailsForm.controls['customDeviceId'].value)) {
        this.equipmentDetailsForm.controls['customDeviceId'].setValue(this.equipmentDetailsForm.controls['customDeviceId'].value.trim());
      }
      const diffOfEquipments: any = Utilities.difference(this.equipmentDetailsForm.getRawValue(), this.equipmentDetails);
      // console.log(diffOfEquipments, '========')
      if (diffOfEquipments['dueDate'] && diffOfEquipments['dueDate'] !== "") {
        diffOfEquipments['dueDate'] = moment(diffOfEquipments['dueDate']).format('MM/DD/YYYY')
      }
      if (diffOfEquipments['installationDate'] && diffOfEquipments['installationDate'] !== "") {
        diffOfEquipments['installationDate'] = moment(diffOfEquipments['installationDate']).format('MM/DD/YYYY')
      }
      if (diffOfEquipments['deviceWarrantyExpirationDate'] && diffOfEquipments['deviceWarrantyExpirationDate'] !== "") {
        diffOfEquipments['deviceWarrantyExpirationDate'] = moment(diffOfEquipments['deviceWarrantyExpirationDate']).format('MM/DD/YYYY')
      }
      if (diffOfEquipments['laborWarrantyExpirationDate'] && diffOfEquipments['laborWarrantyExpirationDate'] !== "") {
        diffOfEquipments['laborWarrantyExpirationDate'] = moment(diffOfEquipments['laborWarrantyExpirationDate']).format('MM/DD/YYYY')
      }
      if (diffOfEquipments['networkSwitchLinkId'] && diffOfEquipments['networkSwitchLinkId'] === "null") {
        diffOfEquipments['networkSwitchLinkFloorId'] = null;
        diffOfEquipments['networkSwitchLinkName'] = null;
        diffOfEquipments['networkSwitchLinkId'] = null;
        diffOfEquipments['cableCoordinates'] = null;
        this.equipmentDetails.cableCoordinates = null;
        this.equipmentDetailsForm.controls['cableCoordinates'].setValue(null);
      } else if ((diffOfEquipments['networkSwitchLinkId'] > 0 && diffOfEquipments['networkSwitchLinkId'] < 1) || (this.equipmentDetailsForm.value.networkSwitchLinkId !== this.equipmentDetails.networkSwitchLinkId)) {
        if (this.equipmentDetailsForm.value.networkSwitchLinkId !== undefined && this.equipmentDetailsForm.value.networkSwitchLinkId !== null && this.netWorkSwitches.filter((e: any) => e.id == this.equipmentDetailsForm.value.networkSwitchLinkId)[0].networkSwitchLinkTempId) {
          diffOfEquipments['networkSwitchLinkTempId'] = this.equipmentDetailsForm.value.networkSwitchLinkId == 'null' ? null : this.netWorkSwitches.filter((e: any) => e.id == this.equipmentDetailsForm.value.networkSwitchLinkId)[0].networkSwitchLinkTempId;
        } else if (this.equipmentDetailsForm.value.networkSwitchLinkId !== undefined && this.equipmentDetailsForm.value.networkSwitchLinkId !== 'null' && this.netWorkSwitches.filter((e: any) => e.id == this.equipmentDetailsForm.value.networkSwitchLinkId).length > 0) {
          diffOfEquipments['networkSwitchLinkId'] = this.equipmentDetailsForm.value.networkSwitchLinkId == 'null' ? null : this.netWorkSwitches.filter((e: any) => e.id == this.equipmentDetailsForm.value.networkSwitchLinkId)[0].id;
        }
        diffOfEquipments['networkSwitchLinkFloorId'] = this.selectedFloor.id;
        diffOfEquipments['networkSwitchLinkName'] = this.equipmentDetailsForm.value.networkSwitchLinkId !== null ? this.netWorkSwitches.filter((e: any) => e.id == this.equipmentDetailsForm.value.networkSwitchLinkId)[0].name : "";
        diffOfEquipments['cableCoordinates'] = null;
        this.equipmentDetails.cableCoordinates = null;
        this.equipmentDetailsForm.controls['cableCoordinates'].setValue(null);
      }
      if (this.equipmentDetailsForm.value.networkSwitchLinkId !== undefined && this.equipmentDetails.networkSwitchLinkId !== null && this.equipmentDetails.networkSwitchLinkId !== undefined && this.equipmentDetails.networkSwitchLinkId > 0) {
        diffOfEquipments['networkSwitchLinkId'] = this.equipmentDetailsForm.controls['networkSwitchLinkId'].value
      }
      if (Object.keys(diffOfEquipments).filter((x: any) => x === 'coverageArea').length === 0 && diffOfEquipments['coverageRange']) {
        delete diffOfEquipments['coverageRange'];
      }
      const transformed = _.transform(diffOfEquipments, (result: any, value: any, key: any) => {
        result[key] = typeof value === "string" ? value.trim() : value
      })
      resolve({ isSave: true, diffOfEquipments: transformed })
    })
  }

  formatMACAddress(event?: any) {
    this.equipmentDetailsForm.controls['macAddress'].setValue(Utilities.formatMACAddress(event.target.value));
    this.saveDetails(false);
  }

  setStatusData(currentStatus: any) {
    if (this.isFormDisabled) {
      return;
    }
    if (this.equipmentDetailsForm.controls['status'].value !== currentStatus) {
      if (currentStatus == 'OperationalWithIssue' && !this.fromProject) {
        this.equipmentDetailsForm.controls['statusCode'].setValue(2)
      } else if (currentStatus == 'NotWorking' && !this.fromProject) {
        this.equipmentDetailsForm.controls['statusCode'].setValue(3)
      } else if (currentStatus == 'Operational' && !this.fromProject) {
        this.equipmentDetailsForm.controls['statusCode'].setValue(1)
      }
      this.equipmentDetailsForm.controls['status'].setValue(currentStatus);
      this.saveDetails(false);
    }
  }

  setInstallStatus(currentStatus: any) {
    if (this.isFormDisabled) {
      return;
    }
    if (this.equipmentDetailsForm.controls['installStatus'].value !== currentStatus) {
      if (currentStatus === 'Installed') {
        if (this.installedByUsers.some((user: any) => this.loggedInUser.user.id === user.key) && this.equipmentDetailsForm.controls['installedByUser'].value === null) {
          this.equipmentDetailsForm.controls['installedByUser'].setValue(this.loggedInUser.user.id)
        }
        if (this.equipmentDetailsForm.controls['installationDate'].value === null || this.equipmentDetailsForm.controls['installationDate'].value === "Invalid date" || this.equipmentDetailsForm.controls['installationDate'].value === "" || this.equipmentDetailsForm.controls['installationDate'].value === undefined) {
          this.equipmentDetailsForm.controls['installationDate'].setValue(moment().endOf("day").format('MM/DD/YYYY'));
        }
        this.equipmentDetailsForm.controls['statusCode'].setValue(6)
      } else if (currentStatus === 'InProgress') {
        this.equipmentDetailsForm.controls['statusCode'].setValue(5)
      } else if (currentStatus === 'Planned') {
        this.equipmentDetailsForm.controls['statusCode'].setValue(4)
      }
      this.equipmentDetailsForm.controls['installStatus'].setValue(currentStatus);
      this.saveDetails(false);
    }
  }

  createDeviceLink() {
    const modalData = {
      isFromSiteDevice: false,
      isFromProjectDevice: false,
      isFromDevice: true,
      deviceName: Utilities.removeWhiteSpace(this.equipmentDetails.name),
      deviceId: this.equipmentDetails.id,
      customerId: this.authService.getCustomerId().customerId
    }
    const initialState: ModalOptions = {
      initialState: modalData,
      class: 'modal-lg', ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(ShareableLinkComponent, initialState);
  }

  out() {
    clearTimeout(this.timer)
    clearTimeout(this.updateTimer)
  }

  fillToolTipObj(event?: any, user?: any) {
    event.stopPropagation();
    this.timer = setTimeout(() => {
      this.toolTipObj.name = user.name + (user.isActive === 0 ? ' (inactive)' : '');
      if (user.name === null || user.name === undefined) {
        this.toolTipObj.name = "(" + user.email + ")" + (user.isActive === 0 ? ' (inactive)' : '');
      }
      if (((screen.height - event.pageY) / screen.height) * 100 < 25) {
        this.toolTipObj.style.top = event.pageY - 100 + 'px';
      } else {
        this.toolTipObj.style.top = event.pageY + 'px';
      }
      this.toolTipObj.style.left = event.pageX + 35 + 'px';

      this.toolTipObj.employer = user.employer;
      this.toolTipObj.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObj.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObj.mobile = user.cellNumber;
      }
      this.toolTipObj.email = user.email;
      this.cdRef.detectChanges();
    }, 300);
    this.toolTipObj = new TooltipModel();

  }
  fillToolTipObjUpdate(event?: any, user?: any) {
    event.stopPropagation();
    this.updateTimer = setTimeout(() => {
      this.toolTipObjUpdate.name = user.name + (user.isActive === 0 ? ' (inactive)' : '');

      if (this.toolTipObjUpdate.name === null || this.toolTipObjUpdate.name === undefined) {
        this.toolTipObjUpdate.name =  "(" + user.email + ")" + (user.isActive === 0 ? ' (inactive)' : '');
      }
      if (((screen.height - event.pageY) / screen.height) * 100 < 25) {
        this.toolTipObjUpdate.style.top = event.pageY - 100 + 'px';
      } else {
        this.toolTipObjUpdate.style.top = event.pageY + 'px';
      }
      this.toolTipObjUpdate.style.left = event.pageX + 35 + 'px';

      this.toolTipObjUpdate.employer = user.employer;
      this.toolTipObjUpdate.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObjUpdate.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObjUpdate.mobile = user.cellNumber;
      }
      this.toolTipObjUpdate.email = user.email;
      this.cdRef.detectChanges();
    }, 300);
    this.toolTipObjUpdate = new TooltipModel();

  }

  preserveLogs() {
    if (!this.isFavoriteDataAvailable) {
      this.createdTime = !Utilities.isEmpty(this.equipmentDetails.createdDateTime) ?
        moment(new Date(this.equipmentDetails.createdDateTime)).format('MM/DD/YYYY') : '';
      this.updatedTime = !Utilities.isEmpty(this.equipmentDetails.modifiedDateTime) ?
        moment(new Date(this.equipmentDetails.modifiedDateTime)).format('MM/DD/YYYY') : '';
      this.flaggedTime = !Utilities.isEmpty(this.equipmentDetails.flagCreatedDateTime) ?
        moment(new Date(this.equipmentDetails.flagCreatedDateTime)).format('MM/DD/YYYY') : '';
    }
  }

  checkEquipmentHasImage() {
    this.hasImage = false;
    if (this.equipmentDetails && this.equipmentDetails.images.filter((x: any) => !x.isDeleted).length > 0) {
      this.hasImage = true;
    }
  }
  onSwitchChangeEvent(event: any) {
    this.equipmentDetailsForm.controls['coverageArea'].setValue(event.target.checked);
    if (event.target.checked) {
      if (this.equipmentDetailsForm.controls['coverageDirection'].value === null || this.equipmentDetailsForm.controls['coverageDirection'].value === undefined) {
        this.equipmentDetailsForm.controls['coverageDirection'].setValue(0);
        this.directionValue = 0;
      }
      if (this.equipmentDetailsForm.controls['coverageAngle'].value === null || this.equipmentDetailsForm.controls['coverageAngle'].value === undefined) {
        this.equipmentDetailsForm.controls['coverageAngle'].setValue(45);
        this.angleValue = 45;
      }
      if (this.equipmentDetailsForm.controls['coverageRange'].value === null || this.equipmentDetailsForm.controls['coverageRange'].value === undefined) {
        this.equipmentDetailsForm.controls['coverageRange'].setValue(20);
        this.rangeValue = 20;
      }
    } else {
      if (this.equipmentDetails.id < 1) {
        this.equipmentDetailsForm.controls['coverageDirection'].setValue(null);
        this.equipmentDetailsForm.controls['coverageAngle'].setValue(null);
        this.equipmentDetailsForm.controls['coverageRange'].setValue(null);
        this.directionValue = 0;
        this.angleValue = 45;
        this.rangeValue = 20;
      } else {
        const getEquipmentDetails = FloorUtils.getEquipment(this.defaultFloor, this.equipmentDetails.id);
        if (getEquipmentDetails && getEquipmentDetails !== undefined) {
          this.equipmentDetailsForm.controls['coverageDirection'].setValue(null);
          this.equipmentDetailsForm.controls['coverageAngle'].setValue(null);
          this.equipmentDetailsForm.controls['coverageRange'].setValue(null);
          this.directionValue = 0;
          this.angleValue = 45;
          this.rangeValue = 20;
        }
      }
      this.equipmentDetails.coverageDirection = this.directionValue;
      this.equipmentDetails.coverageAngle = this.angleValue;
      this.equipmentDetails.coverageRange = this.rangeValue;
      this.updateCoverage.emit(this.equipmentDetails);
    }
    this.cdRef.detectChanges();
    this.saveDetails(false);
  }

  directionChange(event: any, isFromSlider: boolean, isBlur = false) {
    if (!isFromSlider && isNaN(event.target.value)) {
      this.equipmentDetailsForm.controls['coverageDirection'].setValue(this.equipmentDetails.coverageDirection);
      return;
    }
    let newDirectionValue: any = this.equipmentDetails.coverageDirection
    if (event && event.target && event.target.value > 360) {
      this.equipmentDetailsForm.controls['coverageDirection'].setValue(this.directionValue);
      this.equipmentDetails.coverageDirection = this.directionValue;
      this.updateAngle();
      this.updateCoverage.emit(this.equipmentDetails);
      event.stopPropagation();
      return;
    }
    if ((isFromSlider && event.value < 361) || (!isFromSlider && event && event.target && event.target.value < 361)) {
      if (isFromSlider) {
        newDirectionValue = event.value
      } else if (event.target.value !== "" && event.target.value !== undefined) {
        newDirectionValue = parseInt(event.target.value);
      }
      if (!isNaN(newDirectionValue)) {
        if (isBlur) {
          this.directionValue = newDirectionValue;
        }
        this.equipmentDetailsForm.controls['coverageDirection'].setValue(newDirectionValue);
        this.equipmentDetails.coverageDirection = newDirectionValue;
        this.updateAngle();
      }
      this.updateCoverage.emit(this.equipmentDetails);
    } else {
      this.directionValue = _.cloneDeep(this.equipmentDetails.coverageDirection);
    }
  }

  updateAngle() {
    const formValue = this.equipmentDetailsForm.value as any;
    if (formValue.coverageAngle === 360) {
      formValue.coverageAngle = 359.9999
    }
    const dimensionUpdate = CoverageCone.describeArc(0, 0, 100, formValue.coverageDirection - 1 / 2 * formValue.coverageAngle, formValue.coverageDirection + 1 / 2 * formValue.coverageAngle);
    this.equipmentDetailsForm.controls['dimension'].setValue(dimensionUpdate);
  }

  angleChange(event: any, isFromSlider: boolean, isBlur = false) {
    if (!isFromSlider && isNaN(event.target.value)) {
      this.equipmentDetailsForm.controls['coverageAngle'].setValue(this.equipmentDetails.coverageAngle);
      return;
    }
    let newAngleValue: any = this.equipmentDetails.coverageAngle
    if (event && event.target && event.target.value > 360) {
      this.equipmentDetails.coverageAngle = this.angleValue;
      this.equipmentDetailsForm.controls['coverageAngle'].setValue(this.angleValue);
      this.updateAngle();
      this.updateCoverage.emit(this.equipmentDetails);
      event.stopPropagation();
      return;
    }
    if ((isFromSlider && event.value < 361) || (!isFromSlider && event && event.target && event.target.value < 361)) {
      if (isFromSlider) {
        newAngleValue = event.value
      } else if (event.target.value !== "" && event.target.value !== undefined) {
        newAngleValue = parseInt(event.target.value);
      }
      if (newAngleValue === 0) {
        newAngleValue = 1
      }
      if (!isNaN(newAngleValue)) {
        if (isBlur) {
          this.angleValue = newAngleValue;
        }
        this.equipmentDetailsForm.controls['coverageAngle'].setValue(newAngleValue);
        this.equipmentDetails.coverageAngle = newAngleValue;
        this.updateAngle();
      }
      this.updateCoverage.emit(this.equipmentDetails);
    } else {
      this.angleValue = _.cloneDeep(this.equipmentDetails.coverageDirection);
      this.equipmentDetailsForm.controls['coverageAngle'].setValue(this.equipmentDetails.coverageAngle);
    }
  }

  rangeChange(event: any, isFromSlider: boolean, isBlur = false) {
    if (!isFromSlider && isNaN(event.target.value)) {
      this.equipmentDetailsForm.controls['coverageRange'].setValue(this.rangeValue);
      this.cdRef.detectChanges();
      return;
    }
    if (!isFromSlider && event.target.value < 1) {
      event.target.value = this.rangeValue;
      this.equipmentDetails.coverageRange = this.rangeValue;
      this.equipmentDetailsForm.controls['coverageRange'].setValue(this.rangeValue);
      this.updateAngle();
      this.updateCoverage.emit(this.equipmentDetails);
      this.cdRef.detectChanges();
      return;
    }
    if (!isFromSlider && event.target.value > 100) {
      event.target.value = this.rangeValue;
      this.equipmentDetails.coverageRange = this.rangeValue;
      this.equipmentDetailsForm.controls['coverageRange'].setValue(this.rangeValue);
      this.updateAngle();
      this.updateCoverage.emit(this.equipmentDetails);
      this.cdRef.detectChanges();
      return;
    }
    let newRangeValue: any = this.equipmentDetails.coverageRange
    if (event && event.target && event.target.value > 100) {
      event.target.value = this.rangeValue;
      this.equipmentDetails.coverageRange = this.rangeValue;
      this.equipmentDetailsForm.controls['coverageRange'].setValue(this.rangeValue);
      this.updateAngle();
      this.updateCoverage.emit(this.equipmentDetails);
      event.stopPropagation();
      return;
    }
    if ((isFromSlider && event.value < 101) || (!isFromSlider && event && event.target && event.target.value < 101)) {
      if (isFromSlider) {
        newRangeValue = event.value
      } else if (event.target.value !== "" && event.target.value !== undefined) {
        newRangeValue = parseInt(event.target.value);
      }
      if (newRangeValue === 0) {
        newRangeValue = 1
      }
      if (!isNaN(newRangeValue)) {
        if (isBlur) {
          this.rangeValue = newRangeValue;
        }
        this.equipmentDetailsForm.controls['coverageRange'].setValue(newRangeValue);
        this.equipmentDetails.coverageRange = newRangeValue;
        this.updateAngle();
      }
      this.cdRef.detectChanges();
      this.updateCoverage.emit(this.equipmentDetails);
    } else {
      this.equipmentDetailsForm.controls['coverageRange'].setValue(this.equipmentDetails.coverageRange);
      this.cdRef.detectChanges();
    }
  }

  sliderDisable(disable: boolean) {
    if (disable) {
      this.directionValue = this.equipmentDetails.coverageDirection;
      this.angleValue = this.equipmentDetails.coverageAngle;
      this.rangeValue = this.equipmentDetails.coverageRange;
    }
    this.directionOptions = {
      floor: 0,
      ceil: 360,
      showTicks: true,
      disabled: disable,
      ticksArray: [0, 45, 90, 135, 180, 225, 270, 315, 360],
    };
    this.angleOptions = {
      floor: 1,
      ceil: 360,
      disabled: disable,
      showTicks: true,
      ticksArray: [1, 45, 90, 135, 180, 225, 270, 315, 360],
    };

    this.rangeOptions = {
      floor: 1,
      ceil: 100,
      disabled: disable,
      showTicks: true,
      ticksArray: [4, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    };
    if (this.ngxSliderDirection) {
      this.ngxSliderDirection.ngAfterViewInit();
    }
    if (this.ngxSliderAngle) {
      this.ngxSliderAngle.ngAfterViewInit();
    }
    if (this.ngxSliderRange) {
      this.ngxSliderRange.ngAfterViewInit();
    }
  }
  selectDevice(device: any) {
    this.selectDeviceFromAssociatedTab.emit(device);
  }
  getLength() {
    this.associatedCount = 0;
    this.associatedTicketCount = 0;
    this.associatedClosedTicketCount = 0;
    this.associatedWithClosedCount = 0;
    this.associatedDeviceCount = 0;
    this.associatedAuditCount = 0;
    if (this.authService.getUserKeyData().isTicketing) {
      this.serviceTickets = Object.assign([], this.serviceTickets)
      if (this.equipmentDetails && this.equipmentDetails.isArchived) {
        this.associatedWithClosedCount = this.serviceTickets.length;
        this.associatedCount = this.serviceTickets.filter((x: any) => x.status !== 'Closed').length;

        this.associatedTicketCount = this.serviceTickets.filter((x: any) => x.status !== 'Closed').length;
        this.associatedClosedTicketCount = this.serviceTickets.length;
      } else {
        this.associatedWithClosedCount = this.serviceTickets.filter((t: any) => !t.isArchived).length;
        this.associatedCount = this.serviceTickets.filter((x: any) => x.status !== 'Closed' && !x.isArchived).length;

        this.associatedTicketCount = this.serviceTickets.filter((x: any) => x.status !== 'Closed' && !x.isArchived).length;
        this.associatedClosedTicketCount = this.serviceTickets.filter((t: any) => !t.isArchived).length;
      }
    }
    if (this.equipmentDetails && this.equipmentDetails.networkSwitchLinkEquipments && this.equipmentDetails.networkSwitchLinkEquipments.length > 0 && this.equipmentDetails.type === 'device') {
      this.associatedWithClosedCount = this.associatedWithClosedCount + this.equipmentDetails.networkSwitchLinkEquipments.length;
      this.associatedCount = this.associatedCount + this.equipmentDetails.networkSwitchLinkEquipments.length;
      this.associatedDeviceCount = this.equipmentDetails.networkSwitchLinkEquipments.length;
    }
    if (!this.fromProject && this.equipmentDetails && this.equipmentDetails.auditDeviceDetail && this.equipmentDetails.auditDeviceDetail.length > 0 && this.equipmentDetails.type === 'device') {
      this.associatedWithClosedCount = this.associatedWithClosedCount + this.equipmentDetails.auditDeviceDetail.length;
      this.associatedCount = this.associatedCount + this.equipmentDetails.auditDeviceDetail.filter((e: any) => e.status === 'active').length;
      this.associatedAuditCount = this.equipmentDetails.auditDeviceDetail.filter((e: any) => e.status === 'active').length;
    }

    if (this.equipmentDetails && this.equipmentDetails.auditDeviceDetail && this.equipmentDetails.auditDeviceDetail.filter((e: any) => e.status === 'completed').length > 0) {
      this.associatedWithCompletedCount = this.equipmentDetails.auditDeviceDetail.filter((e: any) => e.status === 'completed').length;
    }
    const accordionState = this.getAccordionState();
    if (this.equipmentDetails && !this.isFavorite) {
      this.isOpenAll = Object.keys(accordionState).filter((e: any) => accordionState[e] === true || (!this.equipmentDetails.equipment.isCoverage && e == 4) || (!(!this.fromProject && this.isTicketAccessible) && e == 7) || (!(!this.fromProject && this.associatedAuditCount > 0) && e == 8)).length === Object.keys(accordionState).length;
    }
  }
  toggleCompletedAudit(event: any) {
    this.hideCompletedAudit = !this.hideCompletedAudit;
  }
  goToAudit(item: any) {
    this.router.navigate(['/secure/audit/edit/' + item.id], { queryParams: { selectedTab: 'device' } })
  }
  toggleTicketStatus(event: any) {
    this.canShowClosedTicket = !this.canShowClosedTicket;
    this.userObj = this.authService.getUserKeyData();
    this.userObj.user.hideClosedTickets = this.canShowClosedTicket;
    this.authService.saveUserData(this.userObj);
  }
  goToTicket(item: any) {
    this.router.navigate(['/secure/customer/ticket/edit/', item]);
  }
  openTicketPopup() {
    this.equipCreateTicket.emit(this.equipmentDetails);
  }
  linkTicket() {
    this.equipLinkTicket.emit(this.equipmentDetails);
  }
  filterTickets() {
    if (this.equipmentDetails) {
      this.unlinkedTicket = []
      this.selectedFloor.serviceTicket.forEach((floorTicket: any) => {
        if (this.equipmentDetails && this.equipmentDetails.serviceTickets &&
          this.equipmentDetails.serviceTickets.every((ticket: any) => ticket && floorTicket && ticket.id !== floorTicket.id) && !floorTicket.isArchived) {
          this.unlinkedTicket.push(floorTicket)
        }
      })
      return this.unlinkedTicket;
    }
    return []
  }

  deviceHistory() {
    const initialState: ModalOptions = {
      initialState: { equipmentDetails: this.equipmentDetails, projectId: this.fromProject, domotzUserId: this.buildingDetails.domotzUserId },
      class: 'modal-dialog w-1200'
    };
    this.bsModalRef = this.modalService.show(EquipmentHistoryComponent, initialState);
  }
  checkDuplicateName(name: any, isFromValidation = false) {
    this.countDuplicateName = 0
    if (isFromValidation) {
      if (this.selectedFloor) {
        this.countDuplicateName = this.selectedFloor.floorEquipments.filter((x: any) => x.name === name && !x.refId && !x.isArchived && !(this.fromProject && x.projectId === null) && x.id !== this.equipmentDetails.id).length + 1;
        this.cdRef.detectChanges();
      }
    } else {
      if (this.selectedFloor) {
        this.countDuplicateName = this.selectedFloor.floorEquipments.filter((x: any) => x.name === name && !x.refId && !x.isArchived && !(this.fromProject && x.projectId === null)).length;
        this.cdRef.detectChanges();
      }
    }
  }
  setValidationCheck() {
    this.nameSub = this.equipmentDetailsForm.controls.name.valueChanges.pipe(takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged(),

    )
      .subscribe((res: any) => {
        if (!Utilities.isNull(res)) {
          this.checkDuplicateName(res.trim(), true);
        }
      });
    this.abbSub = this.equipmentDetailsForm.controls.abbreviatedName.valueChanges.pipe(takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged()
    )
      .subscribe((res: any) => {
        if (!Utilities.isNull(res)) {
          this.checkDuplicateAbbreviatedName(res.trim(), true);
        } else {
          this.countDuplicateAbbreviatedName = 0
          this.cdRef.detectChanges();
        }
      });
  }
  checkDuplicateAbbreviatedName(abbreviatedName: any, isFromValidation = false) {
    this.countDuplicateAbbreviatedName = 0
    if (abbreviatedName == '' || abbreviatedName == null || abbreviatedName == undefined) {
      return;
    }
    if (isFromValidation) {
      if (this.selectedFloor) {
        this.countDuplicateAbbreviatedName = this.selectedFloor.floorEquipments.filter((x: any) => x.abbreviatedName === abbreviatedName && !x.refId && !x.isArchived && !(this.fromProject && x.projectId === null) && x.id !== this.equipmentDetails.id).length + 1;
        this.cdRef.detectChanges();
      }
    } else {
      if (this.selectedFloor) {
        this.countDuplicateAbbreviatedName = this.selectedFloor.floorEquipments.filter((x: any) => x.abbreviatedName === abbreviatedName && !x.refId && !x.isArchived && !(this.fromProject && x.projectId === null)).length;
        this.cdRef.detectChanges();
      }
    }
  }
  deviceTypeToggleForFavorite() {

    if (this.favoriteEquipmentDetails.type === 'device') {
      this.createFavoriteForm();
    } else {
      this.createTaskFavoriteForm();
    }
  }
  private createFavoriteForm() {
    this.equipmentDetailsForm = this.fb.group({
      id: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: true }],
      name: ['', [Validators.maxLength(75), Validators.required]],
      abbreviatedName: ['', Validators.maxLength(80)],
      categoryName: [{ value: '', disabled: true }],
      typeName: [{ value: '', disabled: true }],
      partNumber: [''],
      manufacturer: [''],
      installHours: ['', [ValidationService.negativeValueInstallHoursValidator]],
      price: ['', [ValidationService.negativeValueCostValidator]],
      dataType: [null],
      deviceLocation: [null],
      description: [''],
      isEquipmentFavoriteForm: [true],
      isDeleted: [false],
      orderNumber: [{ value: '', disabled: true }],
      pinColor: [null],
      budgetedCost: ['', ValidationService.negativeValueCostValidator]
    });
    this.equipmentDetailsForm.patchValue(this.favoriteEquipmentDetails);
    if (!this.permissions.canEdit && !this.permissions.canManage) {
      this.equipmentDetailsForm.disable();
    }
  }
  private createTaskFavoriteForm() {
    this.equipmentDetailsForm = this.fb.group({
      id: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: true }],
      name: ['', [Validators.maxLength(120), Validators.required]],
      abbreviatedName: ['', Validators.maxLength(75)],
      categoryName: [{ value: '', disabled: true }],
      typeName: [{ value: '', disabled: true }],
      installHours: ['', [ValidationService.negativeValueInstallHoursValidator]],
      dataType: [null],
      description: [''],
      isEquipmentFavoriteForm: [true],
      isDeleted: [false],
      orderNumber: [{ value: '', disabled: true }],
      pinColor: [null],
      isFavorateUpdate: [false],
      budgetedCost: ['', ValidationService.negativeValueCostValidator]

    });
    this.equipmentDetailsForm.patchValue(this.favoriteEquipmentDetails);
    if (!this.permissions.canEdit && !this.permissions.canManage) {
      this.equipmentDetailsForm.disable();
    }
    this.cdRef.detectChanges();
  }
  showEquipmentModalForFavorite(child: any) {
    this.showEquipmentModalForFavorites.emit(child);
  }
  openEquipmentDetailForFav(event: any) {
    this.openEquipmentDetail.emit(event);
  }
  contextClickFav(event: any, item: any) { }
  favoriteEquipmentChildDetailsCount() {
    if (this.fromProject) {
      return this.favoriteEquipmentDetails.childFavouriteEquipments ? this.favoriteEquipmentDetails.childFavouriteEquipments.filter((x: any) => !x.isDeleted).length : 0;

    } else {
      return this.favoriteEquipmentDetails.childFavouriteEquipments ? this.favoriteEquipmentDetails.childFavouriteEquipments.filter((x: any) => !x.isDeleted && x.type === DeviceTypes.Device).length : 0;

    }
  }

  onFilesChange(newDropFile: any) {
    if (newDropFile && newDropFile.length > 0) {
      this.count = 0;
      newDropFile.forEach((element: any, i: any) => {
        const newFile: any = this.fileUploadService.createFromObject(element);
        const reader = new FileReader();
        reader.onloadend = async () => {
          this.invalidFileDragged = false;
          await this.commonImageCompression(reader.result, newFile, true, newDropFile, false, false);
          if (i === newDropFile.length - 1) {
            this.saveDetails(false);
          }
        }
        reader.readAsDataURL(newFile.fileData);
      });
    }
  }
  onFileInvalids(file: any) {
    this.invalidFileDragged = true;
    this.cdRef.detectChanges();
  }
  onInput(event: any) {
    this.inputCalled.emit();
    this.equipmentDetailsForm.markAllAsTouched();
    this.checkDuplicateName(this.equipmentDetailsForm.controls['name'].value.trim(), false);
    this.checkDuplicateAbbreviatedName(this.equipmentDetailsForm.controls['abbreviatedName'], false)
  }
  openLiveFeedPopup() {
    const popupData = {
      data: this.equipmentDetails,
      isProject: false,
      isShow: true,
      floorId: this.equipmentDetails.id
    }

    // this.modal.open(LiveFeedVideoComponent, overlayConfigFactory(popupData, BSModalContext)).result.then(p => {
    //   if (!Utilities.isEmpty(p)) {
    //     p.result.then((item) => {
    //     }, () => { });
    //   }
    // })
  }
  removeFlag() {
    this.removeFlagFromDevice.emit();

  }
  connectionToolTipObj(event: any) {
    event.stopPropagation();
    this.timer = setTimeout(() => {
      if (((screen.height - event.pageY) / screen.height) * 100 < 25) {
        this.toolTipConnectionObj.style.top = event.pageY - 100 + 'px';
      } else {
        this.toolTipConnectionObj.style.top = event.pageY + 'px';
      }
      this.toolTipConnectionObj.style.left = event.pageX + 35 + 'px';
    }, 300);
    this.toolTipConnectionObj = new TooltipModel();
  }
  onSelect(e: any) {
    if (e.id !== 'ticket' && this.equipmentDetails && !this.equipmentDetails.isPublishedToAMT) {
      this.selectedTab = '';
    } else if (e.id !== 'ticket' && this.equipmentDetails && this.equipmentDetails.isPublishedToAMT) {
      this.selectedTab = ''
    } else {

      this.selectedTab = 'ticket'
    }
  }
  goToRefrenceDevice() {
    let isSiteArchived = false
    this.route.queryParams.subscribe((params: any) => {
      isSiteArchived = params['isArchived'];
    })
    if (!isSiteArchived) {
      if (!this.fromProject &&
        (!Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId && this.equipmentDetails.referenceProjectId)) && this.isLiveSystemAccessForCustomer) {
        this.router.navigate([`/secure/project/${this.equipmentDetails.referenceProjectId}/building/${this.equipmentDetails.buildingId}/floor/${this.equipmentDetails.floorId}/details`], {
          queryParams: {
            floorid: this.equipmentDetails.floorId,
            equipmentid: this.equipmentDetails.referenceFloorEquipmentId,
            site: this.equipmentDetails.siteId,
            comp: 'project'
          }
        });
      } else if (!Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId) && this.equipmentDetails.canAccessReference && this.isLiveSystemAccessForCustomer) {
        this.redirectToLiveSystem();
      } else if (this.fromProject && !Utilities.isNull(this.equipmentDetails.referenceFloorEquipmentId) && this.equipmentDetails.referenceFloorEquipmentId && this.isLiveSystemAccessForCustomer) {
        this.redirectToLiveSystem();
      }
    }
  }
  redirectToLiveSystem() {
    this.router.navigate([`/secure/site/${this.equipmentDetails.siteId}/building/${this.equipmentDetails.buildingId}/floor/${this.equipmentDetails.floorId}/details`], {
      queryParams: {
        floorid: this.equipmentDetails.floorId,
        equipmentid: this.equipmentDetails.referenceFloorEquipmentId,
        project: this.equipmentDetails.projectId,
        comp: 'site'
      }
    });
  }

  isAccordionOpen(index: number): boolean {
    const accordionState = this.getAccordionState();
    return accordionState[index] || false;
  }

  onAccordionChange(index: number, isOpen: boolean): void {
    const accordionState = this.getAccordionState();
    accordionState[index] = isOpen;
    this.isOpenAll = Object.keys(accordionState).filter((e: any) => accordionState[e] === true || (!this.equipmentDetails.equipment.isCoverage && e == 4) || (!(!this.fromProject && this.isTicketAccessible) && e == 7) || (!(!this.fromProject && this.associatedAuditCount > 0) && e == 8)).length === Object.keys(accordionState).length;
    this.saveAccordionState(accordionState);
  }
  private loadAccordionState(): void {
    const accordionState = this.getAccordionState();
    Object.keys(accordionState).forEach((key: any) => {
      accordionState[key] = accordionState[key] || false;
    });
  }
  private getAccordionState(): { [key: number]: boolean } {
    const state = localStorage.getItem(stateManage.accordionState);
    return state ? JSON.parse(state) : {};
  }
  private saveAccordionState(state: { [key: number]: boolean }): void {
    localStorage.setItem(stateManage.accordionState, JSON.stringify(state));
  }
  private saveScrollPosition(position: number): void {
    localStorage.setItem(stateManage.scrollPosition, position.toString());
  }

  private getScrollPosition(): number {
    return parseInt(localStorage.getItem(stateManage.scrollPosition) || '0', 10);
  }

  ngAfterViewInit(): void {
    const savedScrollPosition = this.getScrollPosition();
    const accordionElement: any = document.getElementById('device-accordion-panel');
    if (accordionElement && accordionElement !== null) {
      accordionElement.scrollTo(0, savedScrollPosition);
    }
  }

  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent($event: any) {
    const accordionElement: any = document.getElementById('device-accordion-panel');
    if (accordionElement && accordionElement !== null) {
      const scrollPosition: any = accordionElement.scrollTop > 0 ? accordionElement.scrollTop : 0;
      this.saveScrollPosition(scrollPosition);
    }
  }
  openOrCloseAllAccordion() {
    this.isOpenAll = !this.isOpenAll
    const accordionState = this.getAccordionState();
    Object.keys(accordionState).forEach((element: any) => {
      accordionState[element] = this.isOpenAll;
    });
    this.saveAccordionState(accordionState);
  }

  onScroll(event?: Event): void {
    this.scrollTop = event ? (event.target as HTMLElement).scrollTop : this.scrollTop;

    // If scroll is greater than 50px, change top to 28px
    if (this.scrollTop > 50) {
      this.topPosition = 30;
    } else if (this.equipmentDetails.isFlag && this.fromProject) {
      // If conditions are true and not scrolled, keep top at 200px
      const flagDiv = document.getElementById('flagDiv');
      const equipLabelDiv = document.getElementById('equip-label');
      if (flagDiv) {
        this.topPosition = equipLabelDiv ? equipLabelDiv.scrollHeight + flagDiv.scrollHeight + 27 : flagDiv.scrollHeight + 27;
      } else {
        this.topPosition = this.isEdit ? 164 : 130
      }
    } else {
      // Otherwise keep top at 28px
      this.topPosition = 30;
    }
  }
  toggleImageLoader(imgFile: any, isLoading: boolean): void {
    imgFile.isLoading = false;
  }
  detailsTrackBy(index: number, item: any): string | number {
    return item.id; // Assuming each image has a unique `id`
  }
  detailsTrackByDateTime(index: number, item: any): string | number {
    if (item.value.filter((x: any) => !x.isDeleted).length === 0) {
      item.showDateSection = false;
    }
    return item.key; // Assuming each image has a unique `id`
  }
  detailsTrackByStage(index: number, item: any): string | number {
    if (item.value.filter((x: any) => !x.isDeleted).length === 0) {
      item.showImageSection = false;
    }
    return item.key; // Assuming each image has a unique `id`
  }
}
